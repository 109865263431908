.characters{
    .floating-characters{
        padding: rem(200px) 0;
        position: relative;
        margin-bottom: -15%;
        z-index: 99;
        @include respond-below(lg){
            padding: rem(160px) 0;
        }
        //  August 9 start
        @include respond-below(sm){
            padding: rem(100px) 0;
        }
        //  August 9 start
        img{
            position: absolute;
            bottom: -3%;
            @include respond-below(xxxl){
                max-width: rem(591px);
            }
            @include respond-below(xxl){
                max-width: rem(491px);
            }
            @include respond-below(lg){
                bottom: -25%;
                max-width: rem(391px);
            }
            @include respond-below(sm){
                max-width: rem(241px);
            }
        }
    }
}

.shadowverse-feature{    
    &-1{
        padding: rem(305px) 0 rem(120px);
        clip-path: polygon(0% 0, 100% 8%, 100% 90%, 0 100%);
        overflow: hidden;
        position: relative;
        z-index: 1;

        .shadowverse-feature-bg {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($black, 0.5);
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @include respond-below(md){
            padding: rem(220px) 0 rem(120px);
        }
        &.light-purple-gradient{
            background: rgb(74,67,255);
            background: linear-gradient(209deg, rgba(74,67,255,1) 0%, rgba(130,126,255,1) 100%);
        }
        .sf1{
            &-inner{
                flex-direction: row;
                &-title{
                    color:$white;
                    margin-bottom: rem(83px);
                    @include respond-below(xl) {
                        margin-bottom: rem(50px);
                    }
                    @include respond-below(lg){
                        margin-bottom: rem(30px);
                    }
                }
            }
            &-left{
                &-inner{
                    h4,
                    p{
                        color: $white;
                    }
                    p{
                        margin: 0 0 rem(90px) 0;
                        @include respond-below(xxl){
                            font-size: rem(21px);
                            margin: 0 0 rem(60px) 0;
                        }
                        @include respond-below(lg){
                            font-size: rem(18px);
                            margin: 0 0 rem(40px) 0;
                        }
                        @include respond-below(sm){
                            font-size: rem(16px);
                            margin: 0 0 rem(25px) 0;
                        }
                    }
                }
            }
            &-right-image{                
                position: relative;
                width: rem(790px);
                @include respond-below(lg){
                    width: rem(450px);
                    margin: 0 auto;
                }
                @include respond-below(sm){
                    width:100%;
                    margin: 0 auto;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        + .shadowverse-feature-2{
            margin-top: rem(-150px);
            @include respond-below(lg){
                margin-top: rem(-185px);
            }
        }
        
    }
    &-2{
        padding: rem(250px) 0 rem(150px);
        overflow: hidden;
        // transform: skewY(-4deg);
        transform-origin: right top;
        @include respond-below(lg){
            padding: rem(250px) 0 rem(70px);
        }
        &.dark-purple-gradient{
            background: rgb(4,0,110);
            background: linear-gradient(137deg, rgba(4,0,110,1) 0%, rgba(101,95,255,1) 100%);
            position: relative;
            z-index: 0;
            .shadowverse-feature-bg-2 {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba($black, 0.5);
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .sf2{
            &-inner{
                
                // transform: skewY(4deg);
                transform-origin: right top;
                &--right{
                    h4,
                    p{
                        color: $white;
                    }
                    p{
                        margin: 0 0 10% 0;
                        @include respond-below(xxl){
                            font-size: rem(21px);
                            margin: 0 0 rem(60px) 0;
                        }
                        @include respond-below(lg){
                            font-size: rem(18px);
                            margin: 0 0 rem(40px) 0;
                        }
                        @include respond-below(sm){
                            font-size: rem(16px);
                            margin: 0 0 rem(25px) 0;
                        }
                    }
                }
                &--left{
                    @include respond-below(lg){
                        margin-bottom: rem(40px);
                    }
                }
            }
            &-left{
                &-image{
                    position: relative;
                    img{
                        position: absolute;
                        top:0;
                        left:0;
                        width: 100%;
                        height: 100%;
                        // object-fit: cover;   
                        object-fit: contain;
                    }
                }
            }
            &-image-container{
                &-top{
                    max-width: 100%;
                    margin-left: auto;
                    margin-bottom: rem(30px);
                    .sf2-left-image{
                        padding-top: aspect-ratio(585, 438);
                    }
                }
                &-bottom{
                    margin-right: auto;
                    max-width: 100%;
                    .sf2-left-image{
                        padding-top: aspect-ratio(475, 262);
                    }
                }
            }
            &-inner--right {
                margin-left: rem(60px);
                @include respond-below(xxl) {
                    margin-left: 0;
                }
            }
        }
    }
}
.border-white-top-5{
    border-top: 5px solid $white;
    padding-top: rem(28px);
}