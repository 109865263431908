.contact {
    &-form {
        padding-bottom: rem(100px);
        &.submit-game {
            .contact-right {
                margin-top: rem(65px);
                &:first-child {
                    margin-top: -4%;
                    @include respond-below(lg) {
                        margin-top: rem(65px);
                    }
                }
                .contact-right-inner {
                    p {
                        max-width: rem(561px);
                    }
                }
            }
            .contact-left {
                .form-wrap {
                    h3, .h3 {
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &::before {
                            @include icon("\e90f");
                            display: inline-block;
                            font-size: rem(12px);
                            margin-right: rem(30px);
                            transform: rotate(-90deg);
                            transition: 0.3s;
                        }
                    }
                    input[type="date"] {
                        position: relative;
                        &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator{
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            opacity: 0;
                        }
                    }
                    textarea{
                        height: rem(150px);
                    }
                    .game-options{
                        border: 2px solid $gray-800;
                        padding: rem(15px) rem(25px);
                        margin-top: rem(14px);
                        @include respond-below(sm) {
                            padding: rem(15px) rem(18px);
                        } 
                        h6, .h6{
                            margin-bottom: rem(25px);
                            @include respond-below(md) {
                                margin-bottom: rem(18px);
                            } 
                        }
                        h6, .h6, label{
                            color: $white;
                            font-size: rem(20px);
                            font-weight: 500;
                            @include respond-below(md) {
                               font-size: rem(16px);
                            } 
                        }
                        .checkbox, .radio-option {
                            position: relative;
                            display: block;
                            padding: 0;
                            &:last-child{
                                label{
                                    margin-bottom: 0;
                                }
                            }
                            label, .radio-custom-label{
                                margin-bottom: rem(14px);
                                &::before{
                                    height: rem(50px);
                                    width: rem(50px);
                                    border: none;
                                    background-color: $gray-800;
                                    border-radius: 0;
                                    box-shadow: none;
                                    margin: 0 rem(24px) 0 0;
                                    line-height: 1.44;
                                    @include respond-below(md) {
                                        height: rem(30px);
                                        width: rem(30px);
                                        margin: 0 rem(10px) 0 0;
                                    } 
                                }
                                &::after{
                                    @include icon('\e902');
                                    position: absolute;
                                    left: rem(7px);
                                    top: 0;
                                    font-size: rem(35px);
                                    color: $primary-100;
                                    border: none;
                                    transform: none;
                                    opacity: 0;
                                    transition: 0.3s;
                                    @include respond-below(md) {
                                        left: rem(5px);
                                        font-size: rem(20px);
                                    } 
                                }
                            }
                            input[type=checkbox]:checked + label:after, input[type=radio]:checked + label:after {
                                opacity: 1;
                            }
                        }
                    }
                }
                .contact-bottom {
                    margin-bottom: rem(14px);
                    transform: skewY(0deg);
                    transition: 0.5s;
                    padding: rem(100px) rem(90px) rem(45px);
                    @include respond-below(xxxl) {
                        padding: rem(100px) rem(50px) rem(45px);
                    }
                    @include respond-below(xl) {
                        padding: rem(100px) rem(40px) rem(45px);
                    }
                    @include respond-below(lg) {
                        padding: rem(100px) rem(65px) rem(45px);
                    }
                    @include respond-below(md) {
                        padding: rem(100px) rem(20px) rem(45px);
                    }
                    &.active {
                        padding-bottom: rem(45px);
                        @include respond-below(md) {
                            padding: rem(80px) rem(20px) rem(40px);
                        }
                        @include respond-below(lg) {
                            padding-top: rem(100px);
                        }
                        .form-heading {
                            h3, .h3 {
                                &::before {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                    .form-wrap {
                        transform: skewY(0deg);
                    }
                    .c-form-wrap {
                        margin-top: rem(42px);
                    }
                    &.close-contact {
                        padding: rem(45px) rem(90px);
                        transform: inherit;
                        @include respond-below(xxxl) {
                            padding: rem(45px) rem(50px);
                        }
                        @include respond-below(xl) {
                            padding: rem(45px) rem(40px);
                        }
                        @include respond-below(lg) {
                            padding: rem(45px) rem(65px);
                        }
                        @include respond-below(md) {
                            padding: rem(45px) rem(20px);
                        }
                    }
                    &.-submit-wrap{
                        padding-top: rem(38px);
                        padding-bottom: rem(38px);
                        .form-bottom{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    &-top {
        background: $primary-100;
        padding: rem(90px) rem(85px) rem(120px);
        transform: skewY(-4deg);
        overflow: hidden;
        margin-bottom: rem(-30px);
        z-index: 2;
        position: relative;
        @include respond-below(xxxl) {
            padding: rem(50px);
        }
        @include respond-below(xl) {
            padding: rem(40px);
        }
        @include respond-below(lg) {
            padding: rem(90px) rem(65px) rem(90px);
        }
        @include respond-below(md) {
            padding: rem(90px) rem(20px) rem(90px);
        }
        h3, .h3 {
            color: $white;
            letter-spacing: rem(-2px);
            @include respond-below(md) {
                letter-spacing: inherit;
            }
        }
        p {
            font-size: rem(42px);
            line-height: rem(50px);
            color: $white;
            @include respond-below(xxxl) {
                font-size: rem(35px);
                line-height: rem(40px);
            }
            @include respond-below(xxl) {
                font-size: rem(30px);
                line-height: rem(30px);
            }
            @include respond-below(md) {
                font-size: rem(18px);
                line-height: rem(25px);
            }
        }
    }
    &-heading {
        transform: skewY(4deg);
    }
    &-bottom {
        background: $secondary-100;
        padding: rem(127px) rem(85px) rem(76px);
        @include respond-below(xxxl) {
            padding: rem(100px) rem(50px);
        }
        @include respond-below(xl) {
            padding: rem(100px) rem(40px);
        }
        @include respond-below(lg) {
            transform: skewY(-4deg);
            padding: rem(100px) rem(65px);
        }
        @include respond-below(md) {
            padding: rem(100px) rem(20px);
        }
        .form-wrap {
            @include respond-below(lg) {
            transform: skewY(4deg);
            }
        }
        input, select, textarea {
            background-color: $gray-800;
            color: $white;
            margin-top: rem(14px);
            &:first-child {
                margin-top: 0;
            }
            @include placeholder{
                color: inherit;
            }
        }
        textarea {
            height: rem(198px);
        }
        .form-bottom {
            display: flex;
            // flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(20px);
            @include respond-below(xxl) {
                flex-direction: column;
                align-items: flex-start;
            }
            @include respond-below(lg) {
                flex-direction: row;
                align-items: center;
            }
            @include respond-below(sm) {
                flex-direction: column;
                align-items: flex-start;
            }
            .checkbox {
                label {
                    color: $white;
                    margin-bottom: 0;
                    a {
                        color: $white;
                        display: inline-block;
                        &:hover {
                            color: $primary-100;
                        }
                    }
                    &::before {
                        border: rem(2px) solid $white;
                        margin-right: rem(20px);
                    }
                    &::after {
                        border: solid $white !important;
                        border-width: 0 rem(2px) rem(2px) 0 !important;
                    }
                }
            }
            input[type="submit"] {
                margin-top: 0;
                background-color: transparent;
                min-width: rem(238px);
                @include respond-below(xxxl) {
                    min-width: rem(180px);
                } 
                @include respond-below(xxl) {
                    margin-top: rem(20px);
                } 
                @include respond-below(lg) {
                    margin-top: 0;
                } 
                @include respond-below(sm) {
                    margin-top: rem(20px);
                } 
                &:hover {
                    background-color: $primary-100;
                    color: $white;
                }
            }
        }
    }
    &-right {
        transform: skewY(-4deg);
        overflow: hidden;
        transform-origin: top left;
        border: rem(1px) solid $primary-100;
        box-shadow: rem(12px) rem(12px) 0 $primary-100;
        padding: rem(66px) rem(62px) rem(96px) rem(58px);
        margin-top: -4%;
        margin-left: 4%;
        background-color: $white;
        @include respond-below(xxxl) {
            padding: rem(50px) rem(50px) rem(80px);
        }
        @include respond-below(xl) {
            padding: rem(50px) rem(40px) rem(80px);
        } 
        @include respond-below(lg) {
            margin-top: rem(100px);
            margin-left: 0;
            padding: rem(100px) rem(65px); 
        }
        @include respond-below(md) {
            padding: rem(100px) rem(20px); 
            margin-top: rem(80px);
        }
    } 
    &-right-wrap {
        transform: skewY(4deg);
        transform-origin: inherit;
    }
    &-right-inner {
        margin-top: rem(60px);
        &:first-child {
            margin-top: 0;
        }
        h3, .h4 {
            text-transform: uppercase;
            color: $primary-100;
            @include respond-above(sm) {
                font-size: rem(40px);
            }
        }
        p {
            max-width: rem(502px);
            color: $secondary-100;
            margin-bottom: rem(30px);
        }
        .btn {
            min-width: rem(349px);
            @include respond-below(xl) {
                min-width: 100%;
            }
            @include respond-below(lg) {
                min-width: rem(250px);
                font-size: rem(20px);
            }
            @include respond-below(sm) {
                min-width: 70%;
                font-size: rem(16px);
            }
         }
    }
}

// success-message
.success-m {
    &-wrapper {
        margin-top: rem(20px);
        background-color: $white;
    }
    &-top {
        text-align: center;
        padding: rem(25px) rem(15px);
        h4, .h4 {
            color: $primary-100;
            margin-bottom: 0;
            font-size: rem(30px);
            margin-top: rem(10px);
        }
    }
    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: rem(70px);
        height: rem(70px);
        border: rem(4px) solid $primary-100;
        border-radius: 50%;
        img {
            width: rem(35px);
        }
    }
    &-bottom {
        background-color: $primary-100;
        padding: rem(25px) rem(15px);
        text-align: center;
        p {
            color: $white;
            font-weight: 600;
        }
    }
}

// newsletter success message
.newsletter-success-message {
    margin-top: rem(20px);
    .ns {
        &-wrapper {
            padding: rem(10px) rem(20px);
            @include respond-below(md) {
                padding: rem(10px);
            }
        }
        &-wrapper {
            display: flex;
            align-items: center;
            border: rem(1px) solid $primary-100;
            box-shadow: 0.75rem 0.75rem 0 #ff6700;
        }
        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem(35px);
            height: rem(35px);
            min-width: rem(35px);
            border: rem(2px) solid $primary-100;
            border-radius: 50%;
            @include respond-below(md) {
                width: rem(25px);
                height: rem(25px);
                min-width: rem(25px);
            }
            img {
                width: rem(20px);
                @include respond-below(md) {
                    width: rem(13px);
                }
            }
        }
        &-text {
            margin-left: rem(20px);
            @include respond-below(md) {
                margin-left: rem(10px);
            }
            p {
                line-height: 1.3;
                margin-bottom: 0;
                color: $primary-100;
                font-weight: 600;
                &:last-child {
                    color: $gray-500;
                    font-size: rem(18px);
                    font-weight: 400;
                    @include respond-below(md) {
                        font-size: rem(16px);
                    }
                    @include respond-below(sm) {
                        font-size: rem(14px);
                    }
                }
            }
            span {
                color: $gray-500;
                font-size: rem(18px);
                margin-top: rem(-5px);
            }
        }
    }
}
// submit loader
.submit-wrapper {
    position: relative;
    .loading {
        position: absolute;
        right: rem(-30px);
        top: 30%;
        height: 0;
        width: 0;
        padding: rem(10px);
        border: 2px solid $primary-100;
        border-right-color: transparent;
        border-radius: 100vh;
        -webkit-animation: rotate 1s infinite linear;
        @include respond-below(xxl) {
            top: 50%;
        }
        @include respond-below(lg) {
            top: 25%;
        }
        @include respond-below(md) {
            left: rem(-30px);
            top: 20%;
        }
        @include respond-below(sm) {
            right: rem(-30px);
            left: inherit;
            top: 50%;
        }
        &.newsletter-loader {
            left: rem(-30px);
            top: 30%;
            @include respond-below(md) {
                top: 15%;
            }
        }
      }
      @-webkit-keyframes rotate {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
}

  