.quote-bg-image {
    position: relative;
    height: 90vh;
    min-height: rem(600px);
    @include respond-below(md){
        height: 55vh;
        min-height: rem(450px);
    }
    + .buy-game-block{
        margin-top: rem(-85px);
    }
    .container-md{
        height: 100%;
    }
    .qbi{
        &-img{ 
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            @include imgFit();
        }
        &-content{
            position: relative;
            max-width: rem(630px);
            z-index: 2;
            p{
                display: block; 
                font-size: rem(62px);
                color: $white;
                font-weight: 700;
                line-height: 1;
                letter-spacing: -3px;
                @include respond-below(lg){
                    font-size: rem(30px);
                    letter-spacing: -1.5px;
                }
            }
            //August 9 start
            &.shadowverse-qbi {
                max-width: rem(1082px);
            }
            //August 9 start
        }
    }
    &.black-overlay, &.blue-overlay{
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
        }
    }
    &.black-overlay{
        &::before{
            background-image: linear-gradient(0deg, rgba($secondary-100, 0.1), rgba($secondary-100, 0.99));
        }
    }
    &.blue-overlay{
        &::before{
            background-color: rgba($purple, 0.57);
        }
    }
}
