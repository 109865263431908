.full {
    &-width-image {

    }
    &-width-wrapper {
        position: relative;
        .orange-line {
            position: absolute;
            top: 17%;
            left: 0;
            width: 20%;
            height: 3%;
            background-color: $primary-100;
            transform: skewY(-5deg);
        }
    }
    &-img {
        position: relative;
        padding-top: aspect-ratio(1920, 800);
        @include imgFit;
        transform: skewY(-5deg);
        transform-origin: right;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        img {
            transform: skewY(5deg);
            transform-origin: inherit;
            height: 120%;
        }
        &::before {
            content: "";
            position: absolute;
            bottom: -2%;
            right: 0;
            width: 100%;
            height: 6%;
            background-color: #FF6700;
            z-index: 1;
            transform: skewY(0deg);
            transform-origin: right;
        }
    }
}