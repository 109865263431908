// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// Regular ( Open Sans ) font family
$font-family-base:  'Work Sans', 'Arial', sans-serif !default;
// $font-family-hgroup:  'Open Sans', 'Arial', sans-serif !default;
$icon-family: "marvelous-games" !important;

// body contect text size
$font-size-base:  rem($base) !default; //16px (Don't change)

// Colors
$primary-100:   #FF6700 !default;
$primary-200:   #ff3100 !default;
$secondary-100: #0D0D0D !default;

// Start with assigning color names to specific hex values.
$white:         #ffffff !default;
$black:         #000000 !default;
$black-light:   #151515 !default;
$gray:          #dee3e2 !default;
$gray-50:       #EAEAEA !default;
$gray-100:      #F2F2F2 !default;
$gray-300:      #D9D9D9 !default;
$gray-500:      #AAAAAA !default;
$gray-800:      #343434 !default;
$purple:        #04006E;
$purple-100:    #655FFF;

// Body color
$body-bg:       $white !default;
$body-color:    $secondary-100 !default;

// Heading font Size
$headingSize: (
  'h1': 7.3vw,
  'h2': 3.8vw,
  'h3': rem(60px),
  'h4': rem(42px),
  'h5': rem(40px),
  'h6': rem(38px),
);

$hgroup-margin-bottom: rem(15px) !default;
$hgroup-font-family:   inherit !default;
$hgroup-font-weight:   700 !default;
$hgroup-line-height:   1.1 !default;
$hgroup-color:         $primary-100 !default;

// Forms
$label-color:           $black;
$label-font-weight:     400;
$label-font-family:     $font-family-base;
$label-font-size:       rem(14px);

$input-bg:                 $white;
$input-font-family:        $font-family-base;
$input-font-size:          rem(20px);
$input-font-weight:        500;
$input-color:              $black;
$input-color-placeholder:  $black;
$input-height-base:        rem(50px);
$input-radius:             0;
$input-border-color:       $secondary-100;
$input-border-color-focus: $secondary-100;
$textarea-height-base:     rem(150px);

// Set assets path
$img: '../images'; // background-img:url( #{$img}/)
$fonts: '../fonts';

// Container's maximum width
$container-width: rem(1700px) !default;
$container-md: rem(1490px) !default;
$gutters: rem(50px);
$row-gutters: rem(30px);

// Media Query breakpoints.
$breakpoints: (
  'xs': 481px, // Small devices (landscape phones, 481px)
  'sm': 576px, // Small devices (landscape phones, 576px)
  'md': 768px, // Medium devices (tablets, 768px)
  'lg': 992px, // Large devices (desktops, 992px)
  'xl': 1200px, // Extra large devices (large desktops, 1200px)
  'xxl': 1400px, // Extra large devices (large desktops, 1400px)
  'xxxl': 1600px // Extra large devices (large desktops, 1400px)
);

// Advance utilities
$advance-utilities : true;

// Spacing : padding and margin
$spacing : true;
$spaceamounts: (15, 30);

// Background and Text color classes(ex: .bg-white and .text-white)
$bgColor-map: (
  'white' : $white,
  'black' : $black,
  'primary' : $primary-100,
  'secondary' : $secondary-100,
);

/* Note: If need Disable Decimal-value functionilty open function.scss file */