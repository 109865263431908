.media {
    &-title {
        position: relative;
    }
    &-img-wrap {
        position: relative;
        .orange-line {
            position: absolute;
            bottom: -3%;
            right: 0;
            width: 20%;
            height: 3%;
            background-color: $primary-100;
            transform: skewY(-4deg);
        }
        // August 10 start
        @include respond-below(lg) {
            margin-top: rem(-30px);
            z-index: -1; 
        }
        // August 10 end
    }
    &-t-img {
        position: relative;
        padding-top: aspect-ratio(1636, 800);
        @include imgFit;
        margin-left: 12%;
        transform: skewY(-4deg);
        transform-origin: right;
        overflow: hidden;
        @include respond-below(lg) {
            margin-left: 0;
        }
        // August 10 start
        @include respond-below(md) {
            min-height: rem(240px);
        }
        // August 10 end
        img {
            height: 120%;
            width: 100%;
            object-fit: cover;
            transform: skewY(4deg);
            transform-origin: inherit;
        }
    }
    &-t-description {
        margin-left: rem(50px);
        margin-top: -20%;
        margin-right: 15%;
        background-color: $primary-100;
        max-width: rem(860px);
        padding: rem(150px) rem(70px) rem(150px) rem(75px);
        transform: skewY(-4deg);
        transform-origin: right;
        overflow: hidden;
        @include respond-below(xl) {
            padding-top: rem(100px);
            padding-bottom: rem(100px);
        }
        @include respond-below(lg) {
            // August 10 start
            margin-left: rem(50px);
            margin-right: rem(50px);
            max-width: 100%;
            margin-top: rem(-50px); 
            display: none;
            // August 10 end
        }
        @include respond-below(sm) {
            margin-top: rem(-10px);
            padding: rem(100px) rem(20px);
        }
        // August 10 start
        &.-for-mobile {
            margin-top: 0;
            padding: rem(100px) rem(30px);
            @include respond-above(lg) {
                display: none;
            }
            @include respond-below(lg) {
                display: block;
            }
            @include respond-below(md) {
                margin-left: rem(20px);
                margin-right: rem(20px);
                padding: rem(45px) rem(30px);
            }
            p {
                margin-top: 0;
                @include respond-below(md) {
                    font-size: rem(14px);
                    line-height: rem(18px);
                }
            }
        }
        // August 10 end
        h3, .h3 {
            color: $white;
            margin-bottom: 0;
            @include respond-below(xl) {
                font-size: rem(45px);
            }
        }
        p {
            // August 10 start
            font-size: rem(24px);
            line-height: rem(34px);
            // August 10 end
            color: $white;
            margin-top: rem(30px);

            // August 10 start

            // @include respond-below(xxl) {
            //     font-size: rem(30px);
            //     line-height: rem(35px); 
            // }
            // @include respond-below(lg) {
            //     margin-top: rem(20px);
            // }
            // @include respond-below(md) {
            //     font-size: rem(24px);
            //     line-height: 1.5;
            // }
            
            // August 10 end
        }
    }
    &-t-heading {
        transform: skewY(4deg);
    }
}