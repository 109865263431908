// variable and mixin
// @import 'abstracts/core' ;
.home-hero-section{
    height: 90vh;
    min-height: rem(700px);
    overflow: hidden;
    margin-bottom: rem(6px);
    @include respond-below(xxl){
        height: 100vh;
        min-height: auto;
    }
    @include respond-below(lg){
        height: auto;
    }
    .hhs{
        &-slider{
            display: flex;
            height: 100%;
            margin-left: rem(-3px);
            margin-right: rem(-3px);
            background-color: $secondary-100;
            @include respond-below(lg){
                min-height: rem(650px);
            }
            @include respond-below(md){
                min-height: rem(476px);
            }
            .slick{
                &-list{
                    display: flex; 
                }
                &-slide{     
                    @include respond-above(lg){
                        &.slick-active {
                            .hhs-box {
                                width: 69% !important;
                            }
                            + .slick-slide {
                                .hhs-box {
                                    left: -31%;
                                    width: 31% !important;
                                }
                            }
                        }
                        &:last-child {
                            .hhs-box {
                                width: 100% !important;
                            }
                        }
                    }              
                    > div {
                        height: 100%;                      
                    }
                }
                &-arrow{
                    top: auto;
                    bottom: rem(7px);
                    left: 50%;
                    transform: translateX(-50%);
                    @include respond-below(lg){
                        left: rem(8px);
                        transform: none;
                        bottom: rem(5px);
                    }
                }
                &-prev{
                    margin-right: rem(76px);
                }
                &-next{
                    margin-left: rem(76px);
                    @include respond-below(lg){
                        left: auto;
                        right: rem(8px);
                    }
                }
                &-dots{
                    position: absolute;
                    bottom: rem(22px);
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0;                    
                    li{
                        &.slick-active{
                            button{
                                opacity: 1;
                            }
                        }
                    }
                    button{
                        opacity: 0.3;
                    }
                }
            }
        }
        &-box{
            position: relative;
            left: 0%;
            height: 100%;
            width: 100%;
            border-left: 3px solid $white;
            border-right: 3px solid $white;
            transition: 0.5s;
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($black, 0.3);
                z-index: -1;
            }
            &:hover, &.hovered{
                .hhs-excerpt{
                    p{
                        opacity: 1;
                    }
                }
                .hhs-content{
                    &::before{
                        left: rem(19px);
                        bottom: rem(19px);
                        width: calc(100% - 19px);
                        height: 100%;
                        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
                        -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
                        @include respond-below(lg){
                            left: rem(12px);
                            bottom: rem(8px);
                            width: calc(100% - 12px);
                        }
                    }
                    h2{
                        font-style: italic;
                    }
                }
                .order-btn{
                    padding-left: rem(28px);
                    @include respond-below(md){
                        padding-left: rem(17px);
                    }
                }
                .btn{
                    border-color: $white;
                    color: $white;
                    &:hover, &:focus{
                        color: $secondary-100;
                    }                    
                }
            }
            &:not(.hovered){
                .btn:not(.order-btn){
                    @include respond-above(xl){
                        clip-path: polygon(0 55%, 0 56%, 0 55%, 0 55%);
                        height: 0;
                        opacity: 0;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .btn:not(.order-btn){
                @include respond-above(xl){
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    opacity: 1;
                    transition: 0.3s;
                    height: rem(50px);
                }
            }
            .btn{
                display: inline-flex;
                align-items: center;
                &-wrap{
                    margin-top: rem(23px);
                    @include respond-below(lg){
                        margin-top: rem(15px);
                    }
                }
            }
        }
        &-media{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -2;
            @include imgFit();
            video{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &-content{
            position: relative;
            max-width: rem(622px);
            padding: rem(75px) rem(55px);
            @include respond-below(xxl){
                max-width: rem(500px);
                padding: rem(55px) rem(40px);
            }
            @include respond-below(xl){
                max-width: rem(470px);
            }
            @include respond-below(lg){
                padding: rem(60px) rem(35px) rem(35px);
                bottom: rem(80px);
            }
            @include respond-below(md){
                margin-top: rem(200px);
                margin-right: rem(12px);
            }
            @media (max-width:400px) {
                padding: rem(60px) rem(14px) rem(35px) rem(27px);
            }
            &-wrap{
                position: relative;
                display: flex;
                align-items: flex-end;
                height: 100%;
                width: 100%;
                &::before{
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba($black, 0.3);
                    z-index: -1;
                }
            }
            &::before{
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: calc(100% - 50px);
                width: rem(6px);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                background-color: $primary-100;
                transition: 0.3s;
                z-index: -1;
            }
            .eyebrow-text{
                margin-bottom: rem(8px);
            }
            h2, p{
                color: $white;
                transition: 0.3s;
            }
            p{
                @include respond-above(lg){
                    font-size: rem(20px);
                }
            }
            .order-btn{
                transition: 0.3s;
                @include respond-above(xl){
                    padding-left: 0;
                    border-color: transparent;
                }
                &::before{
                    @include icon("\e909");
                    display: inline-block;
                    font-size: rem(27px);
                    margin-right: rem(8px);
                    color: $white;
                    line-height: 0.8;
                    transition: 0.3s;
                    @include respond-below(xl){
                        font-size: rem(20px);
                    }
                    @include respond-below(md){
                        font-size: rem(15px);
                    }
                }
                &:hover {
                    color: $secondary-100;
                    &::before{
                        color: $secondary-100;
                    }
                }
                // August 8 start
                &:focus {
                    display: none !important;
                }
                // August 8 end
            }
            .btn{
                margin-bottom: rem(5px);
                @include respond-above(xl){
                    margin-right: rem(18px);
                    color: transparent;
                    border-color: transparent;
                }
                @include respond-below(xl){
                    font-size: rem(17px);
                    margin-right: rem(14px);
                }
                &:last-child{
                    margin-right: 0;
                }
                &-wrap{
                    transition: 0.3s;
                }
            }
            .store-icon{
                font-size: rem(27px);
                color: $white;
                text-decoration: none;
                margin-right: rem(7px);
                @include respond-below(xl){
                    font-size: rem(18px);
                }
                &:hover, &:focus{
                    color: $secondary-100;
                }
                &-wrap{
                    margin-right: rem(30px);
                    // August 8 start
                    // margin-bottom: rem(10px);
                    // August 8 end
                    @include respond-below(lg){
                        margin-right: rem(20px);
                    }
                }
            }
        }
        &-excerpt{
            display: none;
            @include respond-below(xl){
                display: block;
            }
            p{
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                opacity: 0;
                transition: 0.3s;
                @include respond-below(xl){
                    opacity: 1;
                }
            }
        }
    }
}

/* game detail page hero */
.game-detail-hero{
    overflow: hidden;
    + .main-content{
        position: relative;
        background-color: $white;
        .dark & {
            background-color: $secondary-100;
        }
    }
    .gdh{
        &-sticky-slider{    
            position: fixed;
            top: 0;
            left: 0;
            height: 84vh;
            min-height: rem(700px);
            width: 100%;
            overflow: hidden;
            @include respond-below(xxl){
                min-height: auto;
            }
            @include respond-below(lg){
                display: flex;
                min-height: rem(650px);
                height: auto;
                padding-right: 0 !important;
            }
            @include respond-below(md){
                min-height: rem(417px);
            }       
            .slick{
                &-list{
                    height: 100%;
                    width: 100%;
                    @include respond-below(lg){
                        height: auto;
                    }   
                }
                &-track{
                    height: 100%;
                }
                &-slide{
                    @include respond-below(lg){
                        height: auto;
                    }    
                    > div {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        &-slider{
            display: flex;
            height: 84vh;
            min-height: rem(700px);
            @include respond-below(xxl){
                min-height: auto;
            }
            @include respond-below(lg){
                min-height: rem(650px);
                height: auto;
            }
            @include respond-below(md){
                min-height: rem(417px);
            }
            .slick{
                &-list{
                    display: flex; 
                    // August 8
                    width: 100% !important;
                }
                &-slide{          
                    > div {
                        height: 100%;                      
                    }
                }     
                &.slide {
                       // August 8
                       width: 100% !important;
                }          
            }
            &-nav{
                width: 100% !important;
                max-width: rem(950px);
                padding-left: rem(80px);
                padding-right: rem(80px);
                @include respond-below(xl){
                    max-width: rem(700px);
                    padding-left: rem(60px);
                    padding-right: rem(60px); 
                }
                @include respond-below(md){
                    max-width: rem(550px);
                    padding-left: rem(22px);
                    padding-right: rem(22px);
                }
                @include respond-below(sm){
                    max-width: rem(380px);
                }
                @media (max-width: 374px) {
                    max-width: rem(330px);
                }
                .slick {
                    &-current{
                        .gdh-slider-nav-video, .gdh-slider-nav-img{
                            border-color: $primary-100;
                        }
                    }
                    &-arrow{
                        top: 0;
                        height: rem(58px);
                        width: rem(58px);
                        @include respond-below(xl){
                            height: rem(45px);
                            width: rem(45px);
                        }
                    }
                }
                &-wrap{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding: rem(34px) 0;
                    background-color: $white;
                    @include respond-below(lg){
                        padding: rem(10px) 0;
                    }
                    .dark & {
                        background-color: $secondary-100;
                    }
                
                }
                &-item{
                    padding-left: rem(4px);
                    padding-right: rem(4px);
                }
                &-video, &-img{
                    position: relative;
                    padding-top: aspect-ratio(182, 105);
                    border: 1px solid $white;
                    overflow: hidden;
                    cursor: pointer;
                    transition: 0.3s;
                    @include respond-above(xl){
                        &:hover{
                            border-color: $primary-100;
                        }
                    }
                }
                &-img{
                    @include imgFit();
                }
                &-video {
                    video{
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                    .icon-play{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: $primary-100;
                        z-index: 1;
                        @include respond-above(sm){
                            font-size: rem(28px);
                        }
                    }
                }
            }
        }
        &-item{
            position: relative;
            width: 100%;
            height: 100%;          
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-image: linear-gradient(360deg, rgba($black, 0.9) 2%, rgba($black, 0.01), rgba($black, 0.01)); // August 10 box-shadow 
                pointer-events: none;
            }    
        }
        &-media{
            position: relative;
            height: 101%;
            width: 101% !important;
            @include imgFit();
            video{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }           
        }
        &-content {
            max-width: rem(622px);
            &-wrap {
                position: relative;
                display: flex;
                align-items: flex-end;
                height: 100%;
                z-index: 1;
            }
            &-inner {
                padding: rem(58px) 0;
                width: 100%;
                @include respond-below(lg){
                    padding: rem(25px) 0;
                }
            }
            h2{
                color: $white;
            }
            .store-icon{
                color: $white;
                font-size: rem(27px);
                margin-right: rem(7px);
                @include respond-below(lg){
                    font-size: rem(18px);
                }
                &:hover, &:focus{
                    color: $primary-100;
                }
            }
        }
    }
    .btn{
        &-wrap{
            width: calc(50% + 100px);
            margin-top: rem(30px);
            @include respond-below(lg){
                width: 100%;
                margin-top: rem(15px);
            }
            // August 8
            .-white-orange {
                padding-left: rem(35px);
                padding-right: rem(35px);
            }
        }
    }
}