.terms-content {
    padding: rem(60px) 0;
    @include respond-below(lg) {
        padding: rem(40px) 0;
    }
    .container {
        max-width: rem(1013px);
    }
    &.privacy-policy {
        h5, .h5 {
            font-size: rem(30px);
            font-weight: 600;
            color: $black;
            text-transform: capitalize;
            letter-spacing: 0;
            @include respond-below(md) {
                font-size: rem(24px)
            }
            strong {
                text-transform: lowercase;
            }
        }
        h6, .h6 {
            font-size: rem(26px);
            @include respond-below(md) {
                font-size: rem(18px);
            }
        }
        h4, .h4 {
            font-size: rem(38px);
            font-weight: 600;
            margin: rem(50px) 0 rem(30px);
            @include respond-below(md) {
                font-size: rem(24px);
                margin-top: 0;
            }
        }
        ul {
            .expand-click {
                font-size: rem(30px);
                font-weight: 600;
            }
        }
    }
}