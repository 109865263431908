.newsletter {
    position: relative;
    z-index: 1;
    &-signup {
        max-width: rem(695px);
        padding: rem(64px) rem(50px);
        border: 1px solid $primary-100;
        margin: auto;
        transform: skewY(2.8deg);
        transform-origin: left top;
        background-color: $white;
        box-shadow: 12px 12px 0 $primary-100;
        @include respond-below(lg){
            padding: rem(28px) rem(23px) rem(40px) rem(23px);
        }
        &--inner {
            transform: skewY(-2.8deg);
            p {
                margin-bottom: rem(20px);
            }
        }
        form {
            .formgroup {
                display: flex;
                input[type="submit"] {
                    margin-left: rem(20px);
                    @media (max-width: 374px) {
                        margin-left: rem(10px);  
                    }
                }
            }
        }
    }
    + .about-us-section {
        margin-top: rem(-90px);
    }
}
