.latest-carousel {
    @include respond-below(md) {
        padding-bottom: rem(10px);
    }
    &.of-game-detail{
        border-bottom: 1px solid $white;        
    }
    .content-part {
        @include respond-below(xl) {
            margin-bottom: rem(30px);
        }
        p {
            max-width: rem(500px);
        }
        // August 8 start
        // h1, .h1 {
        //     font-size: rem(110px); 
        //     @include respond-below(xxl) {
        //         font-size: rem(100px);
        //     }
        //     @include respond-below(xl) {
        //         font-size: rem(80px);
        //     }
        //     @include respond-below(lg) {
        //         font-size: rem(60px);
        //     }
        // }
        // August 8 end
    }
    .lc-image-carousel {
        padding-right: rem(95px);
        padding-left: rem(95px);
        @include respond-below(xxl) {
            padding-right: rem(70px);
            padding-left: rem(70px);
        }
        @include respond-below(md) {
            padding-right: 0;
            padding-left: 0;
        }
        .post-card--item {
            max-width: 100%;
            height: 100%;
        }
        .slick-list {
            padding-top: rem(5px) !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .slick-track {
            max-width: 80% !important; // August 9 change width
            transform: translate3d(0, 0, 0) !important;
            perspective: 1000px;
            margin: 0;
            @include mobile-portrait {
                max-width: 84% !important;
                margin-bottom: rem(66px);
            }
        }
        .slick-slide {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            width: 100% !important;
            transition: transform 0.5s;
            pointer-events: none;
            z-index: 0;
            @include mobile-portrait {
                transform: translate3d(15%, 15%, 0);
            }
            &:focus {
                outline: 0;
            }
        }
        .slick-snext, .slick-snext2, .slick-snext3 {
            display: flex;
            perspective: 1000px;
            opacity: 1;
        }

        .slick-current {
            opacity: 1;
            position: relative;
            display: block;
            z-index: 3;
            pointer-events: auto;
            transform: translate3d(0, 0, 0);
        }

        .slick-snext {
            transform: translate3d(15%, 0, -100px);
            z-index: 2;
            @include mobile-portrait {
                transform: translate3d(5%, 5%, 0);
            }
        }

        .slick-snext2 {
            transform: translate3d(30%, 0, -200px);
            z-index: 1;
            @include mobile-portrait {
                transform: translate3d(10%, 10%, 0);
            }
        }
        .slick-snext3 {
            transform: translate3d(50%, 0, -355px);
            z-index: 0;
            @include mobile-portrait {
                transform: translate3d(15%, 15%, 0);
            }
        }
        .slick-arrow {
            @include respond-below(xxl) {
                --sArrowWidth: 50px;
            }
            @include respond-below(md) {
                --sArrowWidth: 44px;
                top: auto;
                bottom: 0;
                transform: translateY(40%);
            }
            &.slick-prev {
                @include respond-below(md) {
                    left: -16px;
                }
            }
            &.slick-next {
                @include respond-below(md) {
                    right: -16px;
                }
            }
        }
        .slick-dots {
            @include respond-below(md) {
                margin: rem(50px) auto;
            }
            @include respond-below(sm) {
                max-width: rem(170px);
            }
        }
    }
}
.latest-news{
    .content-part{
        &-inner{
            .btn{
                margin-top:2.5rem;
                @include respond-below(xl) {
                    margin-top: rem(15px);
                }
            }
        }
    }
}
// post-card
.post {
    &-card {
        &--item {
            max-width: rem(665px);
            padding-bottom: 12px;
            figure {
                position: relative;
                @include imgFit;
                &::after {
                    content: "";
                    position: relative;
                    display: block;
                    padding-top: aspect-ratio(16, 9);
                    background-color: rgba($secondary-100, 0.5);
                    transition: 0.4s;
                    opacity: 0;
                    z-index: 1;
                }
                .plus-sign {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100px;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                }
            }
            .desc {
                padding: rem(30px) rem(55px) rem(30px) rem(30px);
                padding-bottom: rem(60px); //August 9 padding 60
                @include respond-below(md) {
                    padding: rem(13px) rem(25px) rem(25px) rem(13px);
                }
                h6,
                .h6 {
                    font-size: rem(34px); // August 10 add font size
                    margin-bottom: 0;
                    @include respond-below(xxxl) {
                        font-size: rem(30px);
                    }
                    // August 10 start
                    @include respond-below(sm) {
                        font-size: rem(16px);
                    }
                    // August 10 start
                }
            }
        }
        &--inner {
            display: block;
            color: initial;
            text-decoration: none !important;
            border: 1px solid $gray-300;
            box-shadow: 12px 12px 0px rgba($gray-300, 0.25);
            background-color: $white;
            height: 100%;
            transition: 0.3s;
            &:hover {
                transform: translateY(-5px);
                box-shadow: 12px 12px 0px $primary-100;
                border-color: $primary-100;
                figure {
                    &::after {
                        opacity: 1;
                    }
                }
                .plus-sign {
                    opacity: 1;
                }
            }
        }
    }
    &-date {
        color: $secondary-100;
        margin-bottom: rem(6px);
        font-size: rem(20px);
        @include respond-below(md) {
            font-size: rem(16px);
        }
        @include respond-below(xs) {
            font-size: rem(10px);
        }
    }
}
