.join {
    &-the-team {
        padding: rem(100px) 0;
        @include respond-below(lg) {
            padding: rem(50px) 0;
        }
    }
    &-tt-heading {
        max-width: rem(575px);
        padding-left: rem(55px);
        @include respond-below(xl) {
            padding-left: 0;
            margin-bottom: rem(30px);
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
        h2, .h1 {
            // line-height: rem(104px);
            margin-bottom: 0;
            @include respond-below(xl) {
                // line-height: rem(70px);
            }
        }
        // August 10 start
        p {
            font-size: rem(24px);
            line-height: rem(34px);
            margin-top: rem(32px);
            @include respond-below(md) {
                margin-top: rem(20px);
                font-size: rem(16px);
                line-height: rem(20px);
            }
        }
        // August 10 end
    }
    &-right-part {
        padding-left: rem(90px);
        max-width: rem(915px);
        @include respond-below(xl) {
            padding-left: 0;
        }
        .form-wrap {
            flex: 1;
        }
    }
    &-select-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include respond-below(lg) {
            display: flex;
        }
        // August 10 start
        @include respond-below(md) {
            flex-direction: column;
        }
        // August 10 end
        .job-role {
            width: 52%;
            background-color: $white;
            border: rem(3px) solid $primary-100;
            text-transform: uppercase;
            @include respond-below(xl) {
                width: 48%;
            }
            // August 10 start
            @include respond-below(md) {
                width: 70%;
            }
        }
        select {
            @include respond-below(md) {
                margin-top: rem(25px);
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        // August 10 end
        .office {
            width: 38%;
            background-color: $white;
            border: rem(3px) solid $primary-100;
            text-transform: uppercase;
            @include respond-below(xl) {
                width: 48%;
            }
            // August 10 start
            @include respond-below(md) {
                width: 70%;
            }
            // August 10 end
        }
    }
    &-tt-description {
        border-bottom: rem(2px) solid $black;
        padding-bottom: rem(20px); //August 10
        margin-bottom: rem(15px);
        .small-heading {
            border-bottom: rem(2px) solid $black;
            margin-bottom: rem(18px);
            .eyebrow-text {
                text-transform: lowercase;
                font-size: rem(24px); // August 10
                margin-top: rem(22px); //August 10
                @include respond-below(md) {
                    font-size: rem(16px); //August 10
                }
            }
        }
        .job-title {
            .job-heading {
                font-weight: 700;
            }
            // August 10 start
            p {
                margin: rem(18px) 0 rem(28px);
                @include respond-below(md) {
                    line-height: rem(20px);
                    margin-top: rem(10px);
                    margin-bottom: rem(10px);
                }
                // @include respond-below(xxl) {
                //     font-size: rem(20px);
                // }
            }
            // August 10 end
        }
    }
    &-tt-btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: rem(40px);
        .btn {
            min-width: rem(177px);
        }
    }
}