.about-hero {
    &-section {
        position: relative;
        padding: rem(332px) 0;
        @include respond-below(lg) {
            padding: rem(170px) 0;
        }
        // August 10 start
        @include respond-below(md) {
            padding: rem(147px) 0 rem(53px);
        }
        // August 10 end
        &.contact-banner {
            // padding: rem(223px) 0;
            position: relative;
            margin-bottom: rem(40px);
            @include respond-below(lg) {
                padding: rem(150px) 0;
            }
            .about-hero-bg {
                position: absolute;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: rgba($secondary-100, 0.5);
                    z-index: 1;
                }
            }
            .about-hero-heading {
                h1, .h1 {
                    position: relative;
                    z-index: 1;
                    @include respond-below(sm) {
                        font-size: rem(45px);
                    }
                }
            }
        }

        // August 8 start
        &.terms-hero {
            padding: rem(204px) 0 rem(99px);
            margin-bottom: 0;
            @include respond-below(lg) {
            padding: rem(204px) 0 rem(50px);
            }
            @include respond-below(md) {
                padding: rem(120px) 0 rem(50px);
            }
        }
        // August 8 end
    }
    &-bg {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transform: skewY(-4deg);
        transform-origin: top left;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &-heading {
        h1, .h1 {
            color: $white;
        }
    }
}