.about {
    &-fifty-fifty {
        padding-top: rem(40px);
        // August 10 start
        @include respond-below(lg) {
            .row {
                flex-direction: column-reverse;
            }
        }
        // August 10 end
    }
    &-img-wrap {
        position: relative;
        .orange-line {
            position: absolute;
            bottom: -3%;
            right: 0;
            width: 42%;
            height: 3%;
            background-color: $primary-100;
            transform: skewY(-4deg);
        }
    }
    &-left-img {
        position: relative;
        padding-top: aspect-ratio(790, 750);
        margin-bottom: 9%;
        transform: skewY(-4deg);
        transform-origin: right;
        overflow: hidden;
        @include imgFit; 
        @include respond-below(lg) {
            margin-bottom: 0;
        }
        // August 10 start
        @include respond-below(lg) {
            margin-bottom: 10%;
        }
        // August 10 start
        img {
            transform: skewY(4deg);
            transform-origin: inherit;
            height: 108%;
        }
    }
    &-right-part {
        max-width: rem(690px);
        margin-left: 12%;
        // margin: 0 auto;

        border-top: rem(5px) solid $primary-100;
        //August 10 start

        // @include respond-above(md) {
        //     margin-left: 12%;
        // }

        // August 10 end
        @include respond-below(lg) {
            max-width: 100%;
            margin-left: 0;
        }
        p {
            padding-top: rem(35px);
            font-size: rem(40px); // August 10 font-size 40px
            line-height: rem(50px);
            @include respond-below(xxxl) {
                font-size: rem(32px);
                line-height: 1.2;
            }
            @include respond-below(xxl) {
                font-size: rem(28px);
                line-height: 1.3;
            }
            @include respond-below(xl) {
                font-size: rem(24px);
                padding-top: rem(25px);
                line-height: 1.3;
            }
            // August 10 start 
            @include respond-below(lg) {
                // font-size: rem(20px);
                margin-bottom: rem(37px);
            }
            @include respond-below(sm) {
                font-size: rem(18px);
            }
            // August 10 end
        }
    }
}