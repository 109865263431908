.game-features {
    position: relative;
    z-index: 1;
    &:nth-child(even) {
        .gdf-right {
            margin-left: 0 !important;
        }
        .gdf-left-cell {
            padding-left: rem(80px);
            @include respond-below(xl) {
                padding-left: rem(40px);
            }
            @include respond-below(lg) {
                padding-left: rem(50px);
            }
            @include respond-below(md) {
                padding-left: rem(20px);
            }
        }
       @include respond-below(lg) {
        .d-flex {
            flex-direction: column-reverse;
        }
       }
    }
    .game-features-bg {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($black, 0.5);
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .gdf{
        &-left{
            margin-right: rem(45px);
            @include respond-below(xl){
                margin-right: rem(20px);
            }
            @include respond-below(md){
                margin-right: 0;
            }
            &-cell{
                width: 50%;
                padding-left: calc((100% - 1490px) / 2);
                @media (max-width: 1700px) {
                    padding-left: rem(50px);
                } 
                @include respond-below(lg){
                    padding-right: rem(65px);
                    margin-bottom: rem(45px);
                    width: 100%;
                }
                @include respond-below(md){
                    padding-left: rem(20px);
                    padding-right: rem(20px);
                }
            }
        }
        &-right{
            margin-left: rem(45px);
            @include respond-below(xl){
                margin-left: rem(20px);
            }
            @include respond-below(lg){
                padding-left: rem(50px);
                padding-right: rem(50px);
                margin-left: 0;
            }
            @include respond-below(md){
                padding-left: rem(20px);
                padding-right: rem(20px);
            }
            &-cell{
                width: 50%;
                @include respond-below(lg){
                    width: 100%;
                }
            }
        }
        &-top-img, &-bottom-img {
            position: relative;
            @include imgFit();   
        }
        &-top-img{
            padding-top: aspect-ratio(883, 700);
            margin-bottom: rem(45px);
            img {
                object-fit: contain;
            }
            @include respond-below(md){
                margin-bottom: rem(20px);
            }
        }
        &-bottom-img{
            padding-top: aspect-ratio(542, 430);
            img {
                object-fit: contain;
            }
            &-wrap{
                max-width: 100%;
            }
        }
    }
}
