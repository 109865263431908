.discord-cta{
    position: relative;
    padding: rem(280px) 0 rem(108px);
    overflow: hidden;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    @include respond-below(lg){
        padding: rem(210px) 0 rem(80px);  
    }
    .dc{
        &-title{
            max-width: rem(698px);
            position: relative;
            
            @include respond-below(md){
                max-width: rem(326px);
            }
            h2, .h1{
                color: $white;
                @include respond-below(sm){
                    font-size: rem(60px);
                    margin: 0;
                }
            }
        }
        &-slanted-image{
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;            
            &:after{
                content:'';
                position: absolute;
                top:0%;
                left:0;
                width: 100%;
                height: 100%;
                background-color: rgba($black-light, .7);
            }
            img{
                height:100%;
                width:100%;
                object-fit: cover;                
            }
        }
        &-inner{
            @include respond-below(xl){
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
        &-logo-wrap{
            max-width: rem(152px);
            margin:0 0 rem(40px) 0;
            @include respond-below(md){
                margin:rem(40px) auto rem(10px);
            }
            i{
                font-size: rem(152px);
                color: $white;
                @include respond-below(xl){
                    font-size: rem(100px);
                }
                @include respond-below(md){
                    font-size: rem(45px);
                }
            }
            @include respond-below(lg){
                max-width: rem(100px);
            }
        }
    }
    
}