.buy-game-block{
    position: relative;
    padding: rem(150px) 0 rem(95px);
    transform: skewY(-5deg);
    z-index: 9;
    @include respond-below(lg){
        padding: rem(75px) 0 rem(50px);
    }
    + .discord-cta {
        margin-top: rem(-110px);
    }
    .container{
        transform: skewY(5deg);
        > .d-flex {
            margin-left: rem(-20px);
            margin-right: rem(-20px);
        }
    }
    .bgb{
        &-title{
            width: 40%;
            padding-left: rem(20px);
            padding-right: rem(20px);
            @include respond-below(xl){
                width: 100%;
                margin-bottom: rem(13px);
            }
            h2, h1{
                color: $secondary-100;
                font-size: 6.3vw;
                @include respond-below(md) {
                    font-size: rem(60px);
                }
            }
        }
        &-wrapper{
            width: 60%;
            padding-left: rem(20px);
            padding-right: rem(20px);
            @include respond-below(xl){
                width: 100%;
            }
        }
        &-inner{
            display: flex;
            justify-content: flex-end;
            @include respond-below(xl){
                justify-content: center;
            }
            &-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: rem(150px);
                width: 100%;
                margin-right: rem(48px);
                color: $secondary-100;
                text-decoration: none;
                text-align: center;
                margin-bottom: rem(10px);
                @include respond-below(xxl){
                    margin-right: rem(27px);
                    max-width: rem(110px);
                }
                @include respond-below(xl){
                    max-width: rem(90px);
                }
                @include respond-below(sm){
                    max-width: rem(35px);
                }
                &:last-child{
                    margin-right: 0;
                }
                i{
                    display: inline-block;
                    margin: 0 0 rem(18px) 0;
                    font-size: rem(93px);
                    @include respond-below(xxl){
                        font-size: rem(54px);
                    }
                    @include respond-below(md){
                        font-size: rem(36px);
                        margin-bottom: rem(10px);
                    }
                }
                &-name{
                    position: relative;
                    max-width: rem(135px);
                    p{  
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: rem(20px);
                        transform: translateY(-40%);
                        line-height: 1.1;
                        opacity: 0;
                        transition: transform 0.3s, opacity 0.3s;
                        @include respond-below(md){
                            font-size: rem(14px);
                        }
                    }
                }
                &:hover{
                    color: $primary-100;
                    .bgb-inner-item-name{
                        p{
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }
}
