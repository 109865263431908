// Take away comment if want to separate file and remove _ before file name
/* Slick Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	--webkit-touch-callout: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;

	.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	.slick-track,
	.slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		left: 0;
		top: 0;
		display: flex;
		margin-left: auto;
		margin-right: auto;

		.slick-loading & {
			visibility: hidden;
		}
	}

	@at-root .slick-slide {
		height: 100%;
		min-height: 1px;

		[dir="rtl"] & {
			float: right;
		}

		img {
			display: block;
		}

		&.slick-loading img {
			display: none;
		}

		display: none;

		&.dragging img {
			pointer-events: none;
		}

		.slick-initialized & {
			display: block;
		}

		.slick-loading & {
			visibility: hidden;
		}

		.slick-vertical & {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}

	&.slick-dotted {
	}

	// dots navigation
	.slick-dots {
		position: relative;
		display: flex;
		justify-content: center;
		max-width: rem(200px);
		width: 100%;
		margin: rem(15px) auto;
		padding: 0;
		text-align: center;
		li {
			display: inline-block;
			vertical-align: middle;
			height: rem(6px);
    		width: 100%;
			list-style: none;
			line-height: 0;
			padding-left: 1.5px;
			padding-right: 1.5px;

			button {
				display: block;
				width: 100%;
				height: 100%;
				padding: 0;
				border: none;
				font-size: 0;
				background-color: $gray-50;
				transition: background-color ease 0.3s, opacity ease 0.3s;
				outline: none;
				&:focus,
				&:hover {
					outline: none;
					cursor: pointer;
					background-color: $primary-100;
				}
			}

			&.slick-active button {
				background-color: $primary-100;
			}
		}
	}
}

//  arrows
.slick-arrow {
	--sArrowWidth: 72px;
	position: absolute;
	top: calc(50% - (var(--sArrowWidth) / 2));
	padding: 0;
	width: var(--sArrowWidth);
	height: var(--sArrowWidth);
	font-size: 0;
	background-color: $primary-100;
	box-shadow: none;
	border: none;
	transition: 0.3s;
	z-index: 9;
	@include respond-below(lg){
		--sArrowWidth: 44px;
	}
	&::before{
		@include icon('\e90d');
		display: block;
		color: $white;
		font-size: rem(33px);
		@include respond-below(lg){
			font-size: rem(20px);
		}
	}
	&:focus, &:hover {
		outline: none;
		cursor: pointer;
	}
	&.slick-prev {
		left: 0;
		&::before{
			transform: rotate(180deg);
		}
	}
	&.slick-next {
		right: 0;
	}
	&.slick-disabled{
		opacity: 0.3;
		cursor: not-allowed;
	}

	&.slick-hidden {
		display: none;
	}
}
