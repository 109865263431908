/**
 * Basic typography style for copy text
 */
html {
	@include rem-baseline(16px);
	height: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
	min-height: 100%;
	height: auto;
	color: $body-color;
	font-size: rem(24px);
	font-family: $font-family-base;
	line-height: 1.5;
	background-color: $body-bg;
	@include respond-below(xl){
		font-size: rem(20px);
	}
	@include respond-below(md){
		font-size: rem(16px);
	}
	// @include custom-scroll;
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		height: 100%;
	}
	&.scroll-fixed {
		overflow: hidden;
	}
}

.main-container{
	@include respond-below(lg){
		padding-right: 0 !important;
	}
}

// Heading style
@each $key, $size in $headingSize {
	#{$key},
	.#{$key} {
		margin-bottom: $hgroup-margin-bottom;
		color: $hgroup-color;
		font-family: $hgroup-font-family;
		font-size: $size;
		font-weight: $hgroup-font-weight;
		line-height: $hgroup-line-height;
		text-transform: uppercase;
		// word-break: break-word;
		a {
			display: block;
			color: inherit;
			text-decoration: none;
		}
		@if $key == "h1" {
			line-height: 0.8;
			letter-spacing: -10px;
			//August 8 start
			font-size: rem(140px); 
			@media (max-width: 1920px) {
				font-size: 7.3vw;
			}
			//August 8 end
			@include respond-below(xxl) {
				letter-spacing: -7px;
			}
			@include respond-below(lg) {
				font-size: rem(60px);
				letter-spacing: -4px;
			}
		} @else if $key == "h2" {
			line-height: 0.74;
			letter-spacing: -5px;
			//August 8 start
			font-size: rem(80px);
			@media (max-width: 1920px) {
				font-size: 3.8vw;
			}
			//August 8 end
			@include respond-below(xxl) {
				letter-spacing: -3.5px;
			}
			@include respond-below(lg) {
				font-size: rem(40px); 
				letter-spacing: -2px;
			}
		} @else if $key == "h3" {
			font-size: 3.15vw;
			@include respond-below(lg) {
				font-size: rem(25px);
			}
			@include respond-below(md) {
				font-size: rem(21px);
			}
		} @else if $key == "h4" {
			text-transform: none;
			color: $secondary-100;
			@include respond-below(lg) {
				font-size: rem(20px);
			}
		} @else if $key == "h5" {
			letter-spacing: -0.06em;
			@include respond-below(lg) {
				font-size: rem(19px);
			}
		} @else if $key == "h6" {
			color: $secondary-100;
			font-weight: 600;
			text-transform: none;
			@include respond-below(lg) {
				font-size: rem(18px);
			}
		}
	}
}

p {
	margin-bottom: rem(30px);
	line-height: 1.4;
	&:last-child {
		margin-bottom: 0;
	}
}

ul {
	padding-left: rem(10px);
	margin-bottom: rem(15px);

	ul {
		margin-bottom: 0;
	}

	li {
		padding-top: rem(6px);
		padding-bottom: rem(6px);

		&:last-child {
			padding-bottom: 0;
		}
	}
}

ol {
	padding-left: rem(30px);
	margin-bottom: rem(15px);

	ol {
		margin-bottom: 0;
	}

	li {
		padding-top: rem(6px);
		padding-bottom: rem(6px);

		&:last-child {
			padding-bottom: 0;
		}
	}
}

blockquote {
	position: relative;
	display: block;
	margin-bottom: rem(25px);
	padding: rem(20px) rem(20px);
	text-align: left;
	line-height: 2;
	font-size: rem(18px);
	font-style: italic;
	color: $black;
	background-color: $gray;
	border-left: rem(4px) solid $primary-100;

	p {
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
		color: inherit;
	}
}

hr {
	height: 0;
	margin: rem(10px) auto rem(40px);
	border: 0 $black solid;
	border-top-width: rem(1px);
	overflow: visible;
}

img {
	display: inline-block;
	height: auto;
	max-width: 100%;
	vertical-align: top;
	border: none;

	&[src$=".svg"] {
		max-height: 100%;
	}
}
picture {
	display: inline-block;
	vertical-align: top;
}

/* Link
------------------------------------------------------------------------------*/
a {
	color: $primary-100;
	transition: background-color 0.3s ease 0s, color 0.3s ease 0s;

	&:hover,
	&:focus {
		color: $black;
		text-decoration: underline;
		outline: none;
	}
}

/* Tables
------------------------------------------------------------------------------*/
table {
	border-spacing: 0;
	border-collapse: collapse;

	td {
		text-align: left;
		font-weight: normal;
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-bordered {
	width: 100%;
	max-width: 100%;
	text-align: left;

	td,
	th {
		vertical-align: bottom;
		border-bottom: rem(1px) solid $gray;
		padding: rem(10px);
	}
}
