// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500;1,700&display=swap');

@font-face {
  font-family: 'marvelous-games';
  src:  url('#{$fonts}/marvelous-games.eot?ex3rcs');
  src:  url('#{$fonts}/marvelous-games.eot?ex3rcs#iefix') format('embedded-opentype'),
    url('#{$fonts}/marvelous-games.ttf?ex3rcs') format('truetype'),
    url('#{$fonts}/marvelous-games.woff?ex3rcs') format('woff'),
    url('#{$fonts}/marvelous-games.svg?ex3rcs#marvelous-games') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'marvelous-games' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e919";
}
.icon-tiktok:before {
  content: "\e918";
}
.icon-down-arrow:before {
  content: "\e90f";
}
.icon-bxs-contact:before {
  content: "\e900";
}
.icon-bx-world:before {
  content: "\e901";
}
.icon-cross:before {
  content: "\e902";
}
.icon-discord:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-gamepad:before {
  content: "\e905";
}
.icon-instagam:before {
  content: "\e906";
}
.icon-newspaper-sharp:before {
  content: "\e907";
}
.icon-next-release:before {
  content: "\e908";
}
.icon-nintendoswitch:before {
  content: "\e909";
}
.icon-person-circle:before {
  content: "\e90a";
}
.icon-play:before {
  content: "\e90b";
}
.icon-playstation:before {
  content: "\e90c";
}
.icon-right-arrow:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-steam:before {
  content: "\e910";
}
.icon-submit-game:before {
  content: "\e911";
}
.icon-three-dots:before {
  content: "\e912";
}
.icon-twitch:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}
.icon-windows-play:before {
  content: "\e915";
}
.icon-xbox:before {
  content: "\e916";
}
.icon-youtube:before {
  content: "\e917";
}
