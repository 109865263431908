.game-characters {
    // August 12 start
    &.white-bg {
        background-color: $white;
        .gc-content {
            h4, p {
                color: $black;
            }
        }
        .gc-slider {
            &::after {
                display: none;
            }
        }
        // August 12 end
    }
    .gc{
        // August 12 start
        &-slider {
            position: relative;
            &::after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 20%;
                height: 100%;
                background-image: linear-gradient(90deg, rgba($black, 0), rgba($secondary-100, 0.99));
                pointer-events: none;
            }
            // August 12 end
            cursor: grab;
            &-wrap{
                display: flex;
            }
            // August 12 start
            .slick-arrow {
                top: 35%;
                &.slick-disabled {
                    opacity: 0;
                }
            }
            .slick-slide {
                padding: 0 rem(30px);
                @include respond-below(lg) {
                    padding: 0 rem(20px);
                }
            &:first-child {
                padding-left: 0;
            }
            }
            .slick-list {
                padding-right: rem(180px);
                @include respond-below(xxxl) {
                    padding-right: rem(80px);
                }
                @include respond-below(xl) {
                    padding-right: rem(120px);
                }
                @include respond-below(lg) {
                    padding-right: rem(80px);
                }
                @include respond-below(sm) {
                    padding-right: rem(50px);
                }
            }
            // August 12 end
        }
        &-item {
            // padding: 0 rem(30px);
            // &:first-child {
            //     padding-left: 0;
            // }
            // max-width: rem(347px);
            // min-width: rem(347px);
            // @include respond-below(xxl){
            //     max-width: rem(300px);
            //     min-width: rem(300px);
            // }
            // @include respond-below(lg){
            //     max-width: rem(270px);
            //     min-width: rem(270px);
            // }  
            // @include respond-below(sm){
            //     max-width: rem(220px);
            //     min-width: rem(220px);
            // }
            &:not(:last-child){
                margin-right: rem(80px);
                @include respond-below(xl){
                    margin-right: rem(50px);
                }
                @include respond-below(md){
                    margin-right: rem(20px);
                }
            }
        }
        &-img{
            position: relative;
            padding-top: aspect-ratio(343, 483);
            border: 1px solid $gray-800;
            margin-bottom: rem(20px);
            @include imgFit(); 
        }
        &-content{
            white-space: normal;
            h4, .h4{
                font-size: rem(24px);
                margin-bottom: rem(10px);
                @include respond-below(lg){
                    font-size: rem(20px);
                }
            }
            p{
                font-size: rem(20px);
                @include respond-below(lg){
                    font-size: rem(16px);
                }
            }
        }
    }
}









