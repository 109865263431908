.game-four-img {
    .gfi{
        &-wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        &-img{
            border: 1px solid $gray-800;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }  
    &.light-theme {
        background-color: $white;
    } 
}
