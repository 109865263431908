.meet {
    &-the-team {
        padding: rem(150px) 0 rem(97px);
        @include respond-below(xxl) {
            padding: rem(80px) 0 rem(50px); // August 10
        }
        @include respond-below(sm) {
            padding: rem(21px) 0 rem(50px); // August 10
        }
    }
    &-content-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // August 10 start
        @include respond-below(lg) {
            // flex-direction: column;
            // align-items: center;
            justify-content: space-between;
        }
        // August 10 end
    }
    &-heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
        @include respond-below(lg) {
            width: 100%;
        }
        h2, .h2 {
            // font-size: rem(140px); //August 8
            max-width: rem(577px);
            margin-bottom: 0;
            @include respond-below(xl) {
                font-size: rem(85px);
            }
            @include respond-below(lg) {
                font-size: rem(60px);
                margin-left: rem(20px);
            }
            @include respond-below(sm) {
                margin-left: 0;
            }
        }
    }
    //  August 10 start
    &-box-wrapper {
        // width: 25%;
        max-width: rem(400px);
        width: 45%;
        border: 1px solid $gray-300;
        padding: rem(11px) rem(24px);
        box-shadow: 12px 12px 0px rgba($gray-300, 0.25);
        margin-top: rem(50px);
        margin-left: rem(15px);
        transition: 0.3s;
        @include respond-below(lg) {
            border: 1px solid $primary-100;
            box-shadow: 10px 10px 0px $primary-100;
            padding-left: rem(15px);
            padding-right: rem(15px);
        }
        @include respond-below(md) {
            margin-top: rem(20px);
            margin-left: 0;
            &:nth-child(odd) {
                position: relative;
                top: rem(35px);
            }
        }
        // @include respond-below(xl) {
        //     max-width: 45%;
        // }
        // @include respond-below(sm) {
        //     max-width: 100%;
        //     margin-left: 0;
        // }
        &:hover {
            transform: translate(rem(0px), rem(-10px));
            box-shadow: 12px 12px 0px rgba($primary-100, 1);
            border-color: $primary-100;
        }
    }
    // August 10 end
    &-image {
        position: relative;
        padding-top: aspect-ratio(279, 315);
        @include imgFit;
        display: flex;
        justify-content: center;
        img {
            object-fit: contain;
        }
    }
    &-title {
        h6, .h6 {
            margin-bottom: 0;
            line-height: rem(42px);
            @include respond-below(lg) {
                font-size: rem(30px);
                line-height: 1;
            }
            @include respond-below(md) {
                font-size: rem(18px); //August 10 font-size 18px
                line-height: 1;
            }
        }
    }

    // fancybox 
    &-fancybox {
        display: none;
        max-width: rem(972px);
        width: 100%;
        background-color: $white;
        padding: rem(40px) rem(20px);
        .carousel__button {
            right: -65px !important;
            top: 0 !important;
            color: $primary-100 !important;
            width: rem(55px) !important;
            height: rem(55px) !important;
            @include respond-below(xl) {
                top: rem(-65px) !important;
                right: 0 !important;
            }
            @include respond-below(md) {
                width: rem(30px) !important;
                height: rem(30px) !important;
                top: rem(-35px) !important;
            }
            svg {
                width: 100% !important;
                height: 100% !important;
                stroke-width: 1;
            }
        }
        .fancybox__slide {
            @include respond-below(xl) {
                padding-left: rem(70px) !important;
                padding-right: rem(70px) !important;
            }
         }
    }
    &-f-wrapper {
        display: flex;
        align-items: center;
        @include respond-below(sm) {
            flex-direction: column;
        }
    }
    &-f-title {
        h6, .h6 {
            margin-bottom: 0;
            line-height: rem(42px);
            @include respond-below(xl) {
                font-size: rem(25px);
                line-height: 1;
            }
        }
    }
    &-image-wrapper {
        width: 45%;
        @include respond-below(sm) {
            width: 50%;
        }
    }
    &-f-image {
        position: relative;
        padding-top: aspect-ratio(401, 568);
        @include imgFit;
        img {
            object-fit: contain;
        }
    }
    &-f-content {
        width: 55%;
        max-width: rem(450px);
        margin-left: rem(16px);
        flex: 1;
        @include respond-below(sm) {
            width: 100%;
            margin-left: 0;
        }
        p {
            font-size: rem(24px);
            margin-bottom: rem(48px);
            @include respond-below(lg) {
                font-size: rem(20px);
                margin-bottom: rem(30px);
            }
            @include respond-below(md) {
                font-size: rem(18px);
                margin-bottom: rem(20px);
            }
        }
    }
    &-f-title {
        margin-bottom: rem(38px);
        @include respond-below(lg) {
            margin-bottom: rem(20px);
        }
    }
    &-f-social-link {
        ul {
            display: flex;
            padding-left: 0;
            margin-bottom: 0;
        }
        li {
            list-style: none;
            margin-left: rem(29px);
            padding-top: 0;
            padding-bottom: 0;
            &:first-child {
                margin-left: 0;
            }
        }
        a  {
            display: block;
            text-decoration: none;
            font-size: rem(35px);
            color: $primary-100;
            &:hover {
                color: $black;
            }
            @include respond-below(lg) {
                font-size: rem(20px);
            }
        }
    }
}