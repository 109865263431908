.bg-gray-gradient {
    .latest-carousel {
        .slick-dots{
            button{
                background-color: $white;
            }
        }
    }
}
.post {
    &-content {
        padding: rem(40px) 0 0;
        @include respond-below(lg) {
            padding-top: rem(120px);
        }
        .container {
            max-width: rem(1230px);
            margin: 0 auto;
        }
    }
    &-content-wrap {
        border: 1px solid $gray-300;
        box-shadow: 12px 12px 0px rgba($gray-300, 0.25);
        background-color: $white;
    }
    &-banner-bg {
        position: relative;
        padding-top: aspect-ratio(1127, 605);
        @include imgFit;
    }
    &-description {
        padding: 0 rem(60px);
        @include respond-below(lg) {
            padding: 0 rem(20px);
        }
        p {
            @include respond-below(md) {
                margin-bottom: rem(20px);
            }
        }
        h1, .h1 {
            font-size: rem(80px);
            color: $black;
            letter-spacing: rem(-4px);
            padding: rem(25px) 0 rem(30px);
            @include respond-below(xxl) {
                font-size: rem(60px);
                line-height: 0.8;
            }
            @include respond-below(xl) {
                font-size: rem(50px);
            }
            @include respond-below(lg) {
                font-size: rem(40px);
                line-height: 0.9;
                padding-bottom: rem(10px);
            }
        }
        h2, .h2 {
            font-size: rem(40px); // August 10 Font-size 40px
            font-weight: 400; 
            line-height: rem(50px);
            color: $black;
            letter-spacing: normal;
            text-transform: none;
            margin-bottom: rem(40px);
            letter-spacing: 0;
            @include respond-below(xxl) {
                font-size: rem(38px);
                line-height: rem(40px);
            }
            @include respond-below(xl) {
                font-size: rem(34px);
                line-height: rem(40px);
            }
            @include respond-below(md) {
                font-size: rem(28px);
                line-height: rem(30px);
            }
        }
        q {
            display: block;
            max-width: rem(625px);
            text-align: center;
            font-size: rem(68px);
            font-weight: 700;
            color: $primary-100;
            letter-spacing: rem(-5px);
            line-height: rem(60px);
            margin: rem(90px) auto rem(80px);
            &::before, &::after {
                display: none;
            }
            @include respond-below(xl) {
                font-size: rem(50px);
                line-height: rem(40px);
                letter-spacing: (-1px);
                margin: rem(50px) auto;
            }
            @include respond-below(lg) {
                font-size: rem(40px);
                line-height: rem(35px);
            }
            @include respond-below(md) {
                font-size: rem(30px);
                line-height: rem(30px);
                margin: rem(20px) 0;
            }
        }
        .post-img-wrap {
            display: grid;
            margin: rem(88px) 0 rem(109px);
            @include respond-below(lg) {
                margin: rem(50px) 0;
            }
            .post-img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &.p-img1 {
                    grid-column: 1 / 3;
                    position: relative;
                    padding-top: aspect-ratio(1006, 563);
                    @include imgFit;
                  }
            }
        }
        .post-video {
            position: relative;
            margin-bottom: rem(98px);
            padding-top: aspect-ratio(1006, 563);
            @include imgFit;
            @include respond-below(lg) {
                margin-bottom: rem(50px);
            }
            @include respond-below(md) {
                margin-bottom: rem(40px);
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                z-index: 1;
            }
            .icon-wrap {
                width: rem(100px);
                height: rem(100px);
                background-color: $primary-100;
                border-radius: 100vh;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                text-decoration: none;
                z-index: 2;
                @include flex-center;
                @include respond-below(lg) {
                    width: rem(80px);
                    height: rem(80px);
                }
                @include respond-below(lg) {
                    width: rem(60px);
                    height: rem(60px);
                }
                &:hover {
                    .icon-play {
                        transform: scale(1.2);
                    }
                }
            }
            .icon-play{
                color: $white;
                font-size: rem(35px);
                transition: all 0.3s;
                @include respond-below(lg) {
                    font-size: rem(20px);
                }
            }
        }
        .post-footer {
            border-top: rem(5px) solid $primary-100;
            padding-bottom: rem(85px);
            @include respond-below(lg) {
                padding-bottom: rem(30px);
            }
            @include respond-below(md) {
                padding-bottom: rem(15px);
            }
            p {
                padding-top: rem(27px);
                margin-bottom: rem(16px);
                font-weight: 700;
                @include respond-below(md) {
                    font-size: rem(16px);
                    padding-top: rem(15px);
                }
            }
            .social-link {
                display: flex;
                padding-left: 0;
                li {
                    list-style: none;
                    margin-left: rem(28px);
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        text-decoration: none;
                        color: $black;
                        font-size: rem(35px);
                        @include respond-below(lg) {
                            font-size: rem(26px);
                        }
                        &:hover {
                            color: $primary-100;
                        }
                    }
                }
            }
        }
    }
}