/*--- header ---*/
.main-header {
	position: absolute;
	width: 100%;
	height: auto;
	z-index: 999;
	padding: 0 rem(23px);
	pointer-events: none;
	@include respond-below(lg) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 99999;
	}
	@include respond-below(md) {
		padding: 0 rem(12px);
	}
	.brand {
		position: relative;
		display: block;
		width: rem(240px);
		padding: rem(26px) rem(26px) rem(36px) rem(26px);
		background-color: $primary-100;
		filter: drop-shadow(0px 6px 12px rgba($secondary-100, 0.09));
		clip-path: polygon(0 0, 100% 0, 100% 71%, 0% 100%);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 71%, 0% 100%);
		pointer-events: auto;
		@include respond-below(xl) {
			width: rem(160px);
			padding: rem(20px) rem(20px) rem(26px) rem(20px);
		}
		@include respond-below(md) {
			width: rem(123px);
			padding: rem(12px) rem(10px) rem(15px) rem(10px);
		}
		img {
			transition: 0.4s transform;
		}
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 0;
			transition: 0.4s;
			background-color: inherit;
		}
		&:hover {
			&::before {
				height: 6px;
			}
			img {
				transform: scale(1.03);
			}
		}
	}
}
.header{
	&-search{
		&-title{
			display: flex;
			flex-flow: column;
			align-items: center;
			padding: rem(12px) rem(7px) rem(9px);
			border: 1px solid transparent;
			margin-bottom: rem(8px);
			color: $white;
			font-size: rem(13px);
			font-weight: 500;
			text-transform: uppercase;
			text-align: center;
			border-radius: rem(5px);
			line-height: 1;
			cursor: pointer;
			transition: 0.3s;
			@include respond-below(lg){
				display: none;
			}
			&:hover,
			&:focus {
				border-color: $white;
				i {
					top: rem(-3px);
				}
			}
			i {
				position: relative;
				top: 0;
				font-size: rem(32px);
				margin-bottom: rem(7px);
				transition: 0.3s;
				&.icon-gamepad {
					display: flex;
					align-items: center;
					font-size: rem(50px);
					height: rem(30px);
				}
				&.icon-search {
					font-size: rem(26px);
				}
			}
		}
		&-wrap{
			position: relative;
			@include respond-below(lg){
				margin-bottom: rem(23px);
			}			
		}
	}
	&-searchbar{
		position: fixed;
		top: rem(12px);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: 0.3s;
		// August 8 start
		margin-right: rem(10px);
		// August 8 end
		
		@include respond-below(lg){
			position: static;
			transform: none;
		}
		&.is-show{
			opacity: 1;
			visibility: visible;
			pointer-events: all;
			form{
				width: rem(500px);
			}
		}
		.search-close{
			position: absolute;
			left: rem(-35px);
			top: 50%;
			padding: rem(10px);
			transform: translateY(-50%);
			color: $gray-300;
			background: transparent;
			border: none;
			cursor: pointer;
			transition: 0.3s;
			&:hover {
				color: $primary-100;
			}
			@include respond-below(lg){
				display: none;
			}
		}
		form{
			position: relative;
			width: rem(150px);
			height: rem(66px);
			transition: 0.3s;
			// August 8 start
			z-index: 2;
			// August 8 end
			@include respond-below(lg){
				width: 100% !important;
				height: rem(36px);
			}
		}
		input[type="search"]{
			background-color: $gray-800;
			color: $white;
			padding-right: rem(112px);
			border-radius: rem(50px);
			height: 100%;
			text-transform: uppercase;
			@include placeholder{
				color: inherit;
			}
			@include respond-below(lg){
				background-color: $white;
				color: $secondary-100;
				text-transform: uppercase;
				padding-right: rem(21px);
				padding-left: rem(21px);
				font-size: rem(14px);
			}
		}
		input[type="submit"]{
			position: absolute;
			right: rem(8px);
			top: 50%;
			transform: translateY(-50%);
			border-color: $white;
			color: $white;
			cursor: pointer;
			@include respond-below(lg){
				display: none;
			}
		}
		// August 8 start
		.header-search-inner {
			position: relative;
			.search-heading {
				margin-top: rem(20px);
				&:first-child {
					margin-top: 0;
				}
				p {
					font-weight: 700;
				}
			}
			.header-return-message {
				position: absolute;
				width: 100%;
				top: rem(1px);
				background-color: $white;
				padding: rem(94px) rem(27px) rem(50px);
				border-radius: rem(33px);
				box-shadow: 0 1rem 2rem rgb(0 27 64 / 20%);
				@include respond-below(lg) {
					display: none;
				}
				p {
					margin-bottom: 0;
					color: $secondary-100;
				}
			}
		}
		// August 8 end
	}
}
.header-sidebar {
	position: fixed;
	top: 0;
	right: 0;
	justify-content: center;
	align-content: space-between;
	max-width: rem(90px);
	height: 100vh;
	padding: rem(8px) rem(6px) rem(20px) rem(6px);
	background-color: $primary-100;
	pointer-events: auto;
    overflow: hidden auto;
	@include custom-scroll;
	@include respond-above(lg){
        padding-top: rem(8px) !important;
    }
	@include respond-below(lg){
		max-height: 100vh;
		max-width: 100%;
		width: 100%;
		padding: rem(8px) rem(20px) rem(60px);
		height: auto;
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: unset;
		align-items: center;
		clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
		box-shadow: 0 rem(6px) rem(12px) rgba($secondary-100, 0.09);
		transform: translateY(-100%);
		z-index: -1;
		transition: 0.3s;
		overflow: auto;
	}
	&.is-open{
		transform: translateY(0);
		.header-searchbar{
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
	&-top{
		@include respond-below(xl){
			max-width: rem(72px);
		}
		@include respond-below(lg){
    		max-width: rem(327px);
			width: 100%;
			margin-top: rem(15px);
		}
	}
	&-bottom{
		margin-top: rem(15px);
		@include respond-below(lg){
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: row-reverse;
    		max-width: rem(327px);
			width: 100%;
			margin-top: 0;
		}
	}
	ul {
		padding: 0;
		margin: 0;
	}
	li {
		list-style: none;
		color: $white;
		font-size: rem(13px);
		font-weight: 500;
		text-transform: uppercase;
		a {
			display: inline-block;
			color: inherit;
			text-decoration: none;
		}
	}
	/* nav */
	.navigation {
		ul{
			@include respond-below(lg){
				display: flex;
				flex-wrap: wrap;
				margin: 0 rem(-7px);
			}
		}
		li {
			display: flex;
			flex-flow: column;
			align-items: center;			
			margin-bottom: rem(8px);
			padding: 0;
			text-transform: uppercase;
			text-align: center;
			line-height: 1;
			@include respond-below(lg){
				width: 50%;
				font-size: rem(16px);
				padding-left: rem(7px);
				padding-right: rem(7px);
				margin-bottom: rem(14px);
			}		
			a {
				display: flex;
				flex-flow: column;
				align-items: center;
				padding: rem(12px) rem(7px) rem(9px);
				border: 1px solid transparent;
				border-radius: rem(5px);
				width: 100%;
				transition: 0.3s;
				@include respond-below(lg){
					height: 100%;
					border-color: $white;
					box-shadow: 0 rem(3px) rem(12px) rgba($black, 0.15);
					padding: rem(22px) rem(15px);
				}
				&:hover, &:focus {
					border-color: $white;
					i {
						top: rem(-3px);
					}
				}
			}
			i {
				position: relative;
				top: 0;
				font-size: rem(32px);
				margin-bottom: rem(7px);
				transition: 0.3s;
				@include respond-below(lg) {
					font-size: rem(38px);
				}
				&.icon-gamepad {
					display: flex;
					align-items: center;
					font-size: rem(50px);
					height: rem(30px);
					@include respond-below(lg) {
						font-size: rem(58px);
					}
				}
				&.icon-search {
					font-size: rem(26px);
				}
			}
		}
	}
	&-social {
		@include respond-below(lg){
			display: flex;
			align-items: center;
		}
		ul{
			@include respond-below(lg){
				display: flex;
				flex-direction: row-reverse;
				margin: 0 rem(-8px);
			}
		}
		li {
			display: flex;
			justify-content: center;
			padding: 0;
			font-size: rem(24px);
			margin-bottom: rem(8px);
			@include respond-below(lg){
				margin: rem(5px) rem(8px);
			}
			a {
				&:hover,
				&:focus {
					color: $secondary-100;
				}
			}
		}
	}
	&-lang {
		margin: rem(25px) 0;
		i {
			font-size: rem(20px);
			color: $white;
			margin-bottom: rem(4px);
		}
		.lang-dropdown {
			position: relative;
			max-width: rem(42px);
    		margin: 0 auto;
			ul {
				position: absolute;
				left: 0;
				top: 100%;
			}
			li {
				a {
					text-decoration: underline;
					&:hover,
					&:focus {
						color: $secondary-100;
					}
				}
			}
		}
		.lang-title {
			position: relative;
			padding: 0 rem(14px) 0 0;
			font-size: rem(13px);
			font-weight: 500;
			text-transform: uppercase;
			color: $white;
			cursor: pointer;
			transition: 0.3s;
			&::before {
				@include icon("\e90f");
				position: absolute;
				right: 0;
				top: 10px;
				color: $white;
				font-size: 6px;
				line-height: 0;
				transition: 0.3s;
			}
			&.open {
				span {
					opacity: 0.5;
				}
				&::before {
					transform: rotateX(180deg);
				}
			}
		}
		li {
			padding: 0;
		}
	}
	+ .header-searchbar{
		@include respond-below(lg){
			display: none;
		}
	}
}

// Humburger
$hamburger: ".hamburger";
#{$hamburger} {
    --humburgerBox: #{rem(40px)};
    display: none;
    align-items: center;
    justify-content: center;
    width: var(--humburgerBox);
    height: var(--humburgerBox);
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: transparent;
    z-index: 999;
    pointer-events: auto;
    @include respond-below(lg) {
        display: flex;
    }
	@include respond-below(sm) {
		--humburgerBox: #{rem(35px)};
    }
    &:hover,
    &:focus {
        background-color: transparent;
        outline: none;
        cursor: pointer;
    }
    span {
        position: relative;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        border-radius: 0;
        height: 2px;
        width: 100%;
        background: $primary-100;
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
        border-radius: 2px;
        &:before, &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background: $primary-100;
            transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
            border-radius: 2px;
        }
        &:before {
            top: -8px;
        }
        &:after {
            bottom: -8px;
        }
    }
    &.is-clicked {
        span {
            background-color: transparent !important;
            &:before, &:after {
                top: 0;
                background-color: $white;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: translateY(-6px) rotate(-45deg);
                top: 6px;
            }
        }
    }
}

// mobile nav backdrop
.mbnav {
	&-backdrop {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transition: all 0.4s ease-in;
		background: rgba($black, 0.2);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		z-index: 1;
		@include respond-below(lg) {
			z-index: 9999;
		}
	}
	&-open{
		.mbnav-backdrop {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
}