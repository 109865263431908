.online-manuals {
    padding: rem(8px) 0;
    padding-bottom: rem(250px);
    @include respond-below(lg){
        padding-bottom: rem(50px);
    } 
    + .buy-game-block{
        margin-top: rem(-120px);
        @include respond-below(lg){
            margin-top: rem(-50px);
        } 
    }
    h3, .h3{
        letter-spacing: ls(-60);
    }
    .om{
        &-top, &-bottom{
            margin-top: rem(60px);
            margin-bottom: rem(60px);
            @include respond-below(xl){
                margin-top: rem(40px);
                margin-bottom: rem(40px);
            } 
        }
        &-img{
            display: flex;
            align-items: center;
            justify-content: center;
            border: 3px solid $white;
            padding: rem(30px);
            border-radius: rem(25px);
            text-decoration: none;
            transition: 0.3s;         
            @include respond-below(xl){
                padding: rem(20px);
            }   
            &:hover, &:focus{
                border-color: $primary-100;
            }
            img{
                max-width: rem(240px);
                width: 100%;
                height: rem(55px);
                object-fit: contain;
                @include respond-below(xl){
                    max-width: rem(200px);
                } 
                @include respond-below(sm){
                    height: rem(45px);
                }
                @include respond-below(xs){
                    max-width: rem(170px);
                    height: rem(40px);
                }
            }
        }
        &-bottom{
            h6, .h6{
                font-size: rem(24px);
                margin-bottom: rem(4px);
                @include respond-below(xl){
                    font-size: rem(20px);
                }
                @include respond-below(md){
                    font-size: rem(16px);
                }
            }
            p{
                margin-bottom: 0;
            }
        }
    }
}
