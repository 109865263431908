.about-us {
    &-section {
        position: relative;
        padding: rem(230px) 0 10%;
        margin-bottom: rem(90px);
        @include respond-below(md) {
            padding-top: rem(190px);
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(14deg, $primary-200 0%, $primary-100 80%);
            background-size: cover;
            clip-path: polygon(0 1%, 100% 7%, 100% 89%, 0% 100%);
            -webkit-clip-path: polygon(0 1%, 100% 7%, 100% 89%, 0% 100%);
            z-index: -1;
            @include respond-below(lg) {
                background: linear-gradient(90deg, $primary-200 0%, $primary-100 60%);
            }
        }
    }
    &-heading {
        max-width: rem(616px);
        @include respond-below(xl) {
            max-width: 100%;
        }
        h2, .h2 {
            font-size: rem(140px);
            color: $white;
            @include respond-below(xxl) {
                font-size: rem(100px);
            }
            @include respond-below(md) {
                font-size: rem(60px);
                margin-bottom: rem(20px);
            }
        }
    }
    &-description {
        max-width: rem(982px);
        border-top: rem(10px) solid $white;
        @include respond-below(md) {
            border-top: rem(6px) solid $white;
        }
        p {
            color: $white;
            font-size: rem(42px);
            font-weight: 700;
            line-height: rem(50px);
            padding-top: rem(30px);
            margin-bottom: 0;
            @include respond-below(xxl) {
                font-size: rem(30px);
            }
            @include respond-below(xl) {
                font-size: rem(24px);
                line-height: 1.5;
            }
            @include respond-below(md) {
                font-size: rem(18px);
                line-height: 22px;
                padding-bottom: rem(25px);
            }
        }
        .btn {
            &.-white {
                margin-top: rem(30px);
                @include respond-below(sm) {
                display: none;
            }
            }
        }
    }
    &-img {
        max-height: rem(675px);
        transform: skewY(-5deg);
        position: relative;
        transform-origin: right;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rem(12px) rem(12px) 0 $black;
        background-color: $black;
        margin-bottom: -9%;
        padding-top: aspect-ratio(1636, 650);
        @include imgFit;
        @include respond-below(lg) {
            box-shadow: rem(8px) rem(8px) 0 $black;
        }
        @include respond-below(sm) {
            padding-top: aspect-ratio(635, 400);
        }
        img {
            height: 130%;
            width: 100%;
            object-fit: cover;
            transform: skewY(5deg);
            transform-origin: inherit;
        }
    }
}