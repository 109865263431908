%btn {
	display: inline-block;
	padding: rem(7px) rem(30px);
	vertical-align: top;
	color: $secondary-100;
	font-size: rem(20px);
	font-weight: 500;
	line-height: 1.5;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	border: 3px solid $primary-100;
	border-radius: rem(25px);
	background-color: transparent;
	transition: background-color 0.3s ease 0s, color 0.3s ease 0s, border-color 0.3s ease 0s;
	@include respond-below(md){
		border-width: 2px;
		font-size: rem(14px);
		padding: rem(5px) rem(20px);
	}
	&:hover, &:focus, a:hover & {
		color: $white;
		text-decoration: none;
		background-color: $primary-100;
		cursor: pointer;
	}
}
.btn {
	@extend %btn;
	&-link {
		margin-bottom: rem(2px);
		color: $primary-100;
		font-size: $font-size-base;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: background-color 0.3s ease 0s, color 0.3s ease 0s, border-color 0.3s ease 0s;

		&:hover,
		&:focus,
		a:hover & {
			color: $secondary-100;
			text-decoration: none;
			border-color: currentColor;
		}
	}
	&.-black{
		border-color: $black;
		&:hover, &:focus{
			background-color: $black;
		}
	}
	&.-white{
		border-color: $white;
		color: $white;
		&:hover, &:focus{
			background-color: $white;
			color: $secondary-100;
		}
	}
	&.-white-orange{
		border-color: $primary-100;
		color: $white;
	}
	&.-orange-bg{
		background: $primary-100;
		color: $white;
		&:hover, &:focus{
			background: transparent;
			color: $primary-100;
		}
	}
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
	@extend %btn;
	-webkit-appearance: button;
}
