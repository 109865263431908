.games{
    &-block-section{
        padding: rem(88px) 0;
        background: $secondary-100;
        @include respond-below(sm){
            padding: rem(20px) 0 rem(90px);
        }
        .container{
            @include respond-below(sm){
                padding: 0 rem(32px);
            }
        }
    }
    &-block-wrap{
        margin: 0 rem(-15px);
    }
    &-sidebar{
        max-width: rem(344px);
        width: 100%;
        padding-top: rem(10px);
        @include respond-below(xl){
            max-width: 100%;
            width: 100%;
        }
        .search{
            margin-bottom: rem(15px);
            @include respond-between(sm, xl){
                max-width: 49%;
            }
            input[type="search"]{
                border-radius: rem(25px);
                background: $gray-800;
                color: $white;
                @include placeholder{
                    color: $white;
                    text-transform: uppercase;
                }
            }
        }
        .filters-wrap-block{
            position: relative;
            .mob-filter-top{
                padding-right: rem(75px);
                position: relative;
                padding: rem(10px) rem(75px) rem(10px) 0;
                background: $gray-800;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: rem(-32px);
                    width: 100vw;
                    background: $gray-800;
                    z-index: 0;
                    height: 100%;
                }
                .single-filter{
                    margin-bottom: 0;
                }
                .filter-toggle{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    color: $white;
                    font-size: rem(27px);
                    cursor: pointer;
                    .icon-three-dots{
                        border: 2px solid $primary-100;
                        padding: 0 rem(14px);
                        height: rem(36px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .icon-cross{
                        display: none;
                    }
                }
            }
            &.active{
                .mob-filter-top{
                    .filter-toggle{
                        .icon-three-dots{
                            display: none;
                        }
                        .icon-cross{
                            display: block;
                        }
                    } 
                } 
            }
        }
        .filters{
            @include respond-above(xl){
                display: block !important;
            }
            @include respond-below(xl){
                display: flex;
                flex-wrap: wrap;
                margin: 0 rem(-10px);
            }
            @include respond-below(sm){
                display: none;
                position: absolute;
                padding: rem(5px) rem(107px) rem(135px) rem(32px);
                top: 100%;
                left: 0;
                width: calc(100% + 64px);
                margin: 0 rem(-32px);
                background: $gray-800;
                z-index: 3;
            }
            @media(max-width:1199px) and (min-width:575px){
                display: flex !important;
            }
            .single-filter{
                &:first-child{
                    @include respond-below(sm){
                        display: none;
                    }
                }
            }
        }
    }
    &-card-blocks{
        width: calc(100% - 344px);
        padding-left: rem(31px);
        padding-top: rem(10px);
        @include respond-below(xl){
            width: 100%;
            padding-left: rem(15px);
            padding-top: rem(40px);
        }
        .games-card{
            @include respond-above(xl){
                margin-bottom: rem(75px);
            }
        }
        .more-btn{
            width: 100%;
            margin-top: rem(10px);
        }
    }
}
.single-filter{
    margin-bottom: rem(13px);
    position: relative;
    @include respond-below(xl){
        padding: 0 rem(10px);
        width: 50%;
    }
    @include respond-below(sm){
        width: 100%;
        padding: 0;
    }
    span{
        color: $white;
        font-size: rem(20px);
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        @include respond-below(md){
            font-size: rem(14px);
        }
    }
    .select2{
        border: 2px solid $primary-100;
        padding: rem(9px) rem(21px);
        width: 100% !important;
        @include respond-below(sm){
            padding: rem(2px) rem(16px);
        }
        .select2-selection--single{
            height: auto;
            background: transparent;
            border: none;
            
            .select2-selection__rendered{
                padding: 0;
                color: $white;
                @include respond-below(sm){
                    font-size: rem(14px);
                }
            }
            .select2-selection__arrow{
                top: 50%;
                transform: translateY(-50%);
                right: rem(20px);
                height: auto;
                b{
                    display: none;
                }
                &:after{
                    @include icon("\e90f");
                    font-size: rem(11px);
                    color: $primary-100;
                }
            }
        }
        .select2-dropdown{
            position: static;
            border: none;
            margin-top: rem(18px);
        }
        + .select2-container{
            top: calc(100% - 2px) !important;
            left: 0 !important;
            @include respond-below(xl){
                left: rem(10px) !important;
            }
            @include respond-below(sm){
                left: 0 !important;
            }
            .select2-dropdown{
                border: 2px solid $primary-100;
                padding: rem(6px) rem(21px) rem(25px) rem(21px);
                background: $secondary-100;
                border-top: 0;
                @include respond-below(sm){
                    padding: rem(6px) rem(16px) rem(25px) rem(16px);
                    background: $gray-800;
                }
                .select2-search{
                    display: none;
                }
                .select2-results{
                    max-height: rem(156px);
                    overflow: auto;
                    &__options{
                        max-height: inherit;
                        .mCSB_scrollTools{
                            width: rem(12px);
                            opacity: 1;
                            .mCSB_draggerContainer{
                                background-color: $gray-800;
                                @include respond-below(sm){
                                    background-color: $white;
                                }
                                .mCSB_dragger_bar{
                                    width: rem(12px);
                                    border-radius: 0;
                                    background-color: $primary-100;
                                }
                                .mCSB_draggerRail{
                                    width: rem(12px);
                                    background-color: $gray-800;
                                    @include respond-below(sm){
                                        background-color: $white;
                                    }
                                }
                            }
                        }
                    }
                    li{
                        padding: 0;
                        margin-bottom: rem(9px);
                        transition: all .3s;
                        &:hover{
                            color: $primary-100;
                        }
                        &.select2-results__option--selected{
                            color: $primary-100;
                        }
                        &:first-child{
                            display: none;
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    .select2-results__option--selected, .select2-results__option--highlighted{
                        background: transparent;
                    }
                }
            }
        }
    }
}

.select2-container--default .select2-selection--single {background: transparent;}
.games-sidebar .filters .single-filter span {background: transparent;}