.gray {
    //  August 10 start
    &-bg-text {
        // margin-bottom: rem(100px);
        // margin: 0 rem(50px); 
        @include respond-below(lg) {
            margin: rem(30px) 0;
        } 
    }
    //  August 10 end
    &-bg-content {
        transform: skewY(-4deg);
        transform-origin: right;
        overflow: hidden;
        background-color: $gray-50;
        padding: rem(203px) rem(50px);
        @include respond-below(xxl) {
            padding: rem(180px) rem(75px);
        }
        // August 10 start
        @include respond-below(lg) {
            padding: rem(100px) rem(30px); 
        }
        @include respond-below(md) {
            padding: rem(45px) rem(30px);
        }
        // August 10 end
        p {
            transform: skewY(4deg);
            font-size: rem(40px); //August 10 font-size 40
            line-height: rem(50px);
            max-width: rem(1088px);
            margin: 0 auto;
            @include respond-below(xxl) {
                font-size: rem(30px);
                line-height: rem(45px);
            }
            // August 10 start
            @include respond-below(md) {
                font-size: rem(18px);
                line-height: rem(22px);
            }
            // August 10 end
        }
    }
}