.copyrights{
    padding: rem(100px) 0;
    @include respond-below(md){
        padding: rem(50px) 0;
    }
    &-inner{
        .ci{
            &-details{
                @include respond-below(lg){
                    margin-bottom: rem(30px);
                }
                @include respond-below(sm){
                    margin: 0 0 rem(16px) 0;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
                @media (max-width:374px){
                    width: 100%;
                }
                strong{
                    display: block;
                    &.title-border{
                        @include respond-below(sm){
                            padding-top: rem(10px);
                        }
                    }
                }
                &.text-bold{
                    p{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    &-images{
        display: flex;
        justify-content: flex-end;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        @media (max-width:374px){
            justify-content: unset;
         }
        li{
            display: inline-block;
            margin: 0 rem(30px) 0 0;
            padding:0;
            @include respond-below(xxl){
                margin: 0 rem(10px) 0 0;
            }
            @include respond-below(lg){
                margin: 0 rem(15px) 0 0;
            }
            &:last-of-type{
                margin-right: 0;
            }
            img{
                max-width: rem(100px);
                max-height: rem(100px);
                @include respond-below(xxxl){
                    max-width: rem(70px);
                   height: rem(70px);
                }
                @include respond-below(lg){
                    max-width: rem(45px);
                    height: rem(45px);
                }
            }
        }
        
    }
}
