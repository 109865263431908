:root {
	--space-lg: #{rem(90px)};
	--space-md: #{rem(40px)};
	@include respond-below(lg) {
		--space-lg: #{rem(60px)};
		--space-md: #{rem(20px)};
	}
}
.wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-between;
	width: 100%;
	min-height: 100%;
}

.main-container {
	width: 100%;
}

.slick-dots {
	li {
		padding: 0 rem(5px);
	}
}
// spacing
.space {
	&-lg {
		padding-top: var(--space-lg);
		padding-bottom: var(--space-lg);
		&-m {
			margin-top: var(--space-lg);
			margin-bottom: var(--space-lg);
		}
	}
	&-md {
		padding-top: var(--space-md);
		padding-bottom: var(--space-md);
		&-m {
			margin-top: var(--space-md);
			margin-bottom: var(--space-md);
		}
	}
}

/* eyebrow-text */
.eyebrow-text {
	display: inline-block;
	text-transform: uppercase;
	font-weight: 500;
	font-style: italic;
	font-size: rem(20px);
	color: $gray-500;
	@include respond-below(md){
		font-size: rem(14px);
	}
	&.-white {
		color: $white;
	}
}

/* store-icons */
.store-icons{
    .store-icon{
        display: inline-block;
        margin-right: rem(8px);
        margin-bottom: rem(5px);
        text-decoration: none;
        &:hover, &:focus{
            color: $primary-100;
        }
    }
}

/* plus-sign */
.plus-sign{
    width: rem(77px);
    opacity: 0;
    transition: 0.3s;
    &::before{
        content: "";
        display: block;
        padding-top: aspect-ratio(1, 1);
        background-image: image('plus.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

/* bg-secondary text white */
.bg-secondary{
	*:not(.btn, h3, .h3, h1, .h1, .post-card--item .desc *){
		color: $white;
	}
	.store-icon{
		color: $white !important;
		&:hover, &:focus{
			color: $primary-100 !important;
		}
	}
}

/* top border of title */
.title-border{
	padding-top: rem(30px);
	border-top: 4px solid $primary-100;
}

/* shadowverse skew img */
.shadowverse {
	&-bg {
		position: relative;
		padding-top: aspect-ratio(1920, 900);
		clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
		-webkit-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
		@include imgFit;
	}
}

/* bg-gray-gradient */ 
.bg-gray-gradient {
	background-image: linear-gradient(180deg, rgba($white, 1) 0%, rgba($gray-50, 1) 100%);
}