.news-hero{
    min-height: rem(405px);
    position: relative;
    display: flex;
    align-items: flex-end;
    @include respond-below(sm){
        min-height: rem(263px);
    }
    &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        background: linear-gradient(0deg, $white 0%, rgba($white,0) 100%);
        z-index: -1;
    }
    .banner-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img, video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .hero-content{
        max-width: 900px;
        padding-bottom: rem(47px);
        @include respond-below(sm){
            padding-bottom: 0;
        }
        h1{
            color: $primary-100;
            @include respond-above(lg){
                line-height: 0.74;
                letter-spacing: -8.4px;
            }
        }
    }
}