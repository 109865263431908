.publish {
    &-game {
        position: relative;
        padding: rem(250px) 0 rem(150px);
        &:hover {
            .publish-inner-box {
                box-shadow: rem(12px) rem(12px) 0 $primary-100;
                transform: translate(rem(-10px), rem(-10px));
                @include respond-below(sm) {
                    transform: none;
                }
            }
        }
        @include respond-below(lg) {
            padding: rem(150px) 0 rem(100px);
        }
        @include respond-below(sm) {
            padding: rem(60px) 0 rem(40px);
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(#{$img}/publish-comp-bg.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            z-index: -1;
            transform: skewY(-5deg);
            transform-origin: right;
            @include respond-below(sm) {
                border-top: none;
            }
        }
    }
    &-bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &-inner-box {
        position: relative;
        background-color: $white;
        max-width: rem(1035px);
        margin: 0 auto;
        padding: rem(40px) rem(78px) rem(55px) rem(46px);
        transition: 0.3s;
        @include respond-below(sm) {
            padding: rem(25px);
            box-shadow: 12px 12px 0 $primary-100;
            max-width: rem(305px);
        }
        .pb-heading {
            max-width: rem(392px);
            h3, .h3 {
                line-height: rem(48px);
                margin-bottom: 0;
                font-size: rem(60px);
                @include respond-below(xxl) {
                    font-size: rem(55px);
                }
                @include respond-below(xl) {
                    font-size: rem(45px);
                    line-height: rem(40px);
                }
                @include respond-below(sm) {
                    font-size: rem(20px);
                    line-height: 1;
                }
            }
        }
        .pb-description {
            margin-left: rem(20px);
            border-top: rem(5px) solid $primary-100;
            @include respond-below(lg) {
                margin-left: 0;
                margin-top: rem(10px);
                border-top: 0;
            }
            p {
                padding-top: rem(28px);
                @include respond-below(lg) {
                    font-size: rem(20px);
                }
                @include respond-below(sm) {
                    font-size: rem(16px);
                    padding-top: 0;
                }
            }
        }
    }
    &-btn {
        display: flex;
        justify-content: center;
        margin-top: rem(45px);
        @include respond-below(lg) {
            justify-content: flex-start;
        }
        @include respond-below(sm) {
            margin-top: rem(16px);
        }
        .btn {
            padding: rem(13px) rem(80px);
            border-radius: 100vh;
            @include respond-below(md) {
                padding: rem(13px) rem(50px);
            }
            @include respond-below(sm) {
                font-size: rem(12px);
                padding: rem(7px) rem(13px);
            }
        }
    }
}