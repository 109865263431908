.option-popup{
    display: none;
    max-width: rem(973px);
    width: 100%;
    padding: rem(50px) rem(55px);
    @include respond-below(lg){
        padding: rem(40px) rem(30px);
    }
    &-wrap{
        margin: 0 rem(-15px);
        .popup-content{
            @include respond-below(sm){
                margin-bottom: rem(40px);
            }
            &-wrap{
                padding-bottom: rem(50px);
                height: 100%;
                position: relative;
                
                .single-filter{
                    max-width: rem(313px);
                    margin-bottom: rem(25px);
                    width: 100%;
                    @include respond-below(xl){
                        padding: 0;
                    }
                    @include respond-below(sm){
                        max-width: 100%;
                    }
                    .select2{
                        .select2-selection--single{
                            .select2-selection__rendered{
                                color: $gray-800;
                            } 
                        } 
                        + .select2-container{
                            @include respond-below(xl){
                                left: 0 !important;
                            }
                            .select2-dropdown{
                                background: $white;
                                ul{
                                    li{
                                        color: $gray-800;
                                    }
                                    .mCSB_scrollTools{
                                        .mCSB_draggerContainer{
                                            background: $gray-50;
                                            .mCSB_draggerRail{
                                                background: $gray-50;
                                            }
                                        }
                                    } 
                                }
                            }
                        }
                        
                        &.select2-container--open{
                            .select2-selection--single{
                                .select2-selection__rendered{
                                    color: $primary-100;
                                } 
                            } 
                        }
                    } 
                }

                .buy-btn{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    max-width: rem(313px);
                    @include respond-below(sm){
                        max-width: 100%;
                    }
                    .btn{
                        width: 100%;
                        background: $primary-100;
                        color: $white;
                        &:hover{
                            background: transparent;
                            color: $primary-100;
                        }
                    }
                }
            }
            h3{
                margin-bottom: rem(45px);
                @include respond-below(xl){
                    font-size: rem(38px);
                }
            }
        }
        .popup-image{
            @include respond-below(sm){
                justify-content: center;
            }
        }
    }
    .carousel__button{
        top: 0 !important;
        left: calc(100% + 22px);
        width: rem(65px);
        height: rem(46px);
        svg{
            width: rem(65px);
            height: rem(65px);
            stroke-width: 1px;
            color: #ff6700;
        }
        @media(max-width:1023px){
            top: rem(-40px) !important;
            left: inherit;
            width: rem(35px);
            height: rem(35px);
        }
    }
}
.fancybox__backdrop{
    background: rgba(0,0,0,.95);
}