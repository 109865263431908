.shadowverse-slider{
    padding: rem(112px) 0 rem(140px);
    @include respond-below(lg){
        padding: rem(80px) 0;
    }
    .shadow-left-col{
        padding-left: calc((100% - 1490px) / 2);
        width: 47%;
        @media(max-width: 1700px){
            padding-left: rem(50px);
        }
        @include respond-below(xl){
            width: 50%;
        }
        @include respond-below(lg){
            width: 100%;
            padding-right: rem(50px);
        }
        @include respond-below(md){
            padding:0 rem(20px);
        }
        .shadow-left{
            p{
                font-size: rem(42px);
                line-height: 1.19;
                @include respond-below(xxl){
                    font-size: rem(36px);
                }
                @include respond-below(xl){
                    font-size: rem(30px);
                }
                @include respond-below(md){
                    font-size: rem(25px);
                }
            }
        }
    }
    .shadow-right-col{
        padding: 0  0 rem(67px) rem(140px);
        width: 53%;
        @include respond-below(xxxl){
            padding: 0  0 rem(67px) rem(50px);
        }
        @include respond-below(xl){
            width: 50%;
            padding: 0  0 rem(67px) rem(15px);
        }
        @include respond-below(lg){
            width: 100%;
            padding: rem(50px) rem(50px) rem(67px) rem(50px);
        }
        @include respond-below(md){
            padding: rem(50px) rem(20px) rem(67px) rem(20px);
        }
        .shadow-carousel{
            .single-image{
                figure{
                    position: relative;
                    padding-bottom: 110%;
                    img{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .slick-arrow{
                top: unset;
                bottom: rem(-67px);
                --sArrowWidth: 55px;
                &:before{
                    font-size: rem(26px);
                }
                &.slick-next{
                    right: unset;
                    left: rem(67px);
                }
            }
        }
    }
}