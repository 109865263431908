.developer-detail {
    .dd{
        &-left, &-right{
            &-line{                
                padding: rem(28px) 0;
                @include respond-below(md){
                    padding: rem(20px) 0;
                }
            }
        }
        &-left{
            &-line{
                h4, .h4{
                    font-size: rem(24px);
                    margin-bottom: rem(4px);
                    // August 10 start
                    @include respond-below(md) {
                        font-size: rem(16px);
                    }
                    // August 10 end
                }
                .store-icons {
                    margin-top: rem(7px);
                }
                .store-icon{
                    color: $secondary-100;
                    &:hover, &:focus{
                        color: $primary-100;
                    }
                }
            }
            &-cell{
                @media (max-width:374px) {
                    width: 100%;
                }
            }
        }
        &-right{
            &-line{    
                @include respond-below(md){
                    padding-bottom: 0;
                }            
                p{
                    margin-bottom: rem(25px);
                }
            }
        }
    }
}
