.news-blog-section{
    padding-bottom: 90px;
    background: $gray-50;
    background: linear-gradient(0deg, $gray-50 0%, rgba($white, 1) 100%);
    .container{
        @include respond-below(sm){
            padding: 0 rem(32px);
        }
    }
    .games-sidebar{
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin-bottom: rem(45px);
        &-wrap{
            .search{
                width: 25%;
                @include respond-below(xl){
                    width: 100%;
                }
                input[type="search"]{
                    background: $gray-50;
                    color: $secondary-100;
                    @include placeholder{
                        color: $secondary-100;
                        text-transform: uppercase;
                    }
                }
            }
            .filters-wrap-block{
                width: 75%;
                @include respond-below(xl){
                    width: 100%;
                }
                .mob-filter-top{
                    .single-filter{
                        .select2-dropdown{
                            background: $white;
                            @include respond-below(sm){
                                background: $gray-800;
                            }
                            li{
                                color: $secondary-100;
                                @include respond-below(sm){
                                    color: $white;
                                }
                            }
                        }
                        .select2-container--open{
                            .select2-selection--single{
                                .select2-selection__rendered{
                                    color: $gray-500;
                                }
                            } 
                        }
                    }
                }
                .filters{                    
                    flex-wrap: wrap;
                    padding-left: rem(10px);
                    padding-right: rem(110px);
                    justify-content: space-between;
                    @include respond-above(xl){
                        display: flex !important;
                    }
                    @include respond-below(xxl){
                        padding-right: 0;
                    }
                    @include respond-below(xl){
                        padding-left: 0;
                    }
                    @include respond-below(sm){
                        padding: rem(5px) rem(107px) rem(135px) rem(32px);
                    }
                    .left-filter{
                        width: calc(100% - 237px);
                        display: flex;
                        @include respond-below(sm){
                            width: 100%;
                        }
                        .single-filter{
                            &:first-child{
                                @include respond-below(sm){
                                    display: none;
                                }
                            }
                        }
                    }
                    .sort-filter{
                        width: 237px;
                        @include respond-below(sm){
                            width: 100%;
                        }
                        .single-filter{
                            width: calc(100% - 20px);
                            @include respond-below(xl){
                                width: 100%;
                            }
                        }    
                    }
                    .single-filter{
                        max-width: rem(333px);
                        width: calc(50% - 20px);
                        margin: 0 rem(10px) rem(15px);
                        @include respond-below(xl){
                            margin: 0 0 rem(15px);
                        }
                        @include respond-below(lg){
                            width: 50%;
                        }
                        @include respond-below(sm){
                            width: 100%;
                            max-width: 100%;
                        }
                        .select2{
                            .select2-selection--single{
                                .select2-selection__rendered{
                                    color: $secondary-100;
                                    @include respond-below(sm){
                                        color: $white;
                                    }
                                }
                            } 
                            + .select2-container{
                                .select2-dropdown{
                                    background: $white;
                                    @include respond-below(sm){
                                        background:$gray-800;
                                    }
                                    .select2-results{
                                        color: $secondary-100;
                                        @include respond-below(sm){
                                            color: $white;
                                        }
                                    }
                                    .select2-results__options{
                                        .mCSB_scrollTools{
                                            .mCSB_draggerContainer{
                                                background-color: $gray-50;
                                                .mCSB_draggerRail{
                                                    background: $gray-50;
                                                }
                                            }
                                        } 
                                        .select2-results__option--highlighted{
                                            &.select2-results__option--selectable{
                                                color: $primary-100;
                                            }
                                        }
                                    } 
                                }
                            } 
                            &.select2-container--open{
                                .select2-selection--single{
                                    .select2-selection__rendered{
                                        color: $gray-500;
                                    }
                                } 
                            }
                        } 
                        &:first-child{
                            @include respond-below(sm){
                                display: block;
                            }
                        }                        
                    }
                }
            }             
        }
    }
    .news-blogs{
        max-width: rem(1360px);
        margin: auto;
        .blog-wrap{
            // display: inline-block;
            // column-count: 2;
            // margin-left: 0;
            // overflow: auto;
            padding-top: rem(120px);
            @include respond-below(lg){
                padding-top: rem(70px);
            }
            @include respond-below(md){
                // column-count: 1;
                padding-top: 0;
            }
            .blog-item{
                display: inline-block;
                // padding-left: 0;
                width: 48%;
                @include respond-below(xl) {
                    width: 100%;
                }
                .post-card{
                    &--item{
                        margin-top: rem(15px);
                        padding-bottom: rem(15px);
                        .desc{
                            padding-bottom: rem(42px);
                            .post-date{
                                margin-bottom: rem(3px);
                            }
                        }
                    }
                    &--inner{
                        box-shadow: rem(15px) rem(15px) 0 rgb(217 217 217 / 25%);
                        &:hover{
                            transform: translateY(-15px);
                            box-shadow: rem(15px) rem(15px) 0 $primary-100;
                            border-color: $primary-100;
                        }
                    }
                }
                &:first-child{
                    margin-top: rem(-105px);
                    @include respond-below(lg){
                        margin-top: rem(-65px);
                    }
                    @include respond-below(md){
                        margin-top: 0;
                    }
                }
                .post-card--item {
                    @include respond-below(xl) {
                        max-width: 100%;
                    }
                }
            }
        }
        .more-btn{
            margin-top: rem(90px);
            @include respond-below(md){
                margin-top: rem(50px);
            }
            a.btn{
                min-width: rem(177px);
            }
        }
    }
}
.pattern-background{
    &.gray-bg{
        background: $gray-50;
    }
}
