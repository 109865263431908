/*--- footer ---*/
.main-footer {
	position: relative;
	width: 100%;
	height: auto;
	background: $secondary-100;
	line-height: normal;
	padding: rem(70px) 0;
	// August 8
	// z-index: 999;
	@include respond-below(md){
		padding: rem(40px) 0 rem(15px);
	}
	ul{
		margin: 0;
		padding: 0;
	}
	li{
		list-style: none;
	}
	a:not(.btn){
		text-decoration: none;
		color: $white;
		&:hover, &:focus{
			color: $primary-100;
		}
	}
	p{
		color: $white;
		letter-spacing: -1.5px;
		@include respond-below(md){
			letter-spacing: 0;
		}
	}
	.h5{
		letter-spacing: -2px;
		@include respond-above(md){
			font-size: rem(40px);
		}
		@include respond-below(md){
			letter-spacing: -1px;
			margin-bottom: rem(9px);
		}
	}
	.footer{
		&-top{			
			padding-bottom: rem(40px);
			@include respond-below(md){
				padding-left: rem(15px);
				padding-right: rem(15px);
				padding-bottom: 0;
			}
			[class*=cell-] {
				margin-bottom: rem(40px);
			}
		}
		&-left{
			max-width: rem(640px);
			form{
				display: flex;
				@include respond-between(lg, xl){
					flex-wrap: wrap;
				}
				@include respond-below(md){
					max-width: rem(400px);
				}
			}
			input[type="email"]{
				background-color: $gray-800;
				color: $white;
				margin-right: rem(20px);
				@include respond-between(lg, xl){
					width: 100%;
					margin-right: 0;
					margin-bottom: rem(10px);
				}
				@include respond-below(md){
					margin-right: rem(15px);
				}
				@include placeholder{
					color: inherit;
				}
			}
		}
		&-right{
			max-width: rem(610px);
			.btn{
				margin-right: rem(50px);
				@include respond-below(xl){
					margin-right: rem(28px);
				}
			}
		}
		&-social{
			@include respond-below(md){
				width: 100%;
				margin-top: rem(55px);
			}
			ul{
				@include respond-below(md){
					justify-content: center;
				}
			}
			li{
				margin: rem(5px) rem(15px);
				@include respond-below(xl){
					margin: rem(5px) rem(10px);
				}
			}
			a{
				font-size: rem(36px);
				@include respond-below(xl){
					font-size: rem(28px);
				}
				@include respond-below(md){
					font-size: rem(31px);
				}
			}
		}
		&-bottom{
			span{
				display: inline-block;
				margin-right: rem(28px);
				@include respond-below(md){
					margin-right: rem(9px);
				}
			}
			a{
				display: inline-block;
				margin-right: rem(28px);
				text-decoration: underline;
				@include respond-below(md){
					margin-right: rem(9px);
				}
			}
			&-right{
				@include respond-below(md){
					display: none;
				}
			}
		}
	}
}
