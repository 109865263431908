.pattern-background {
    position: relative;
    padding-bottom: rem(107px);
    overflow: hidden;
    .pattern-bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin-top: rem(100px);
    }
}