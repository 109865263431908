// games-card
.games{
    &-section{
        padding: rem(40px) 0;
    }
    &-heading{
        margin-bottom: rem(20px);
        @include respond-below(md){
            margin-bottom: rem(10px);
        }
        h1, .h1{
            margin-right: rem(15px);
        }
    }
    &-wrap{
        overflow: hidden;    
    }
    &-media{
        position: relative;
        display: block;
        text-decoration: none;
        margin-bottom: rem(25px);
        transition: 0.3s;
        // August 10 start
        @include respond-below(md) {
            margin-bottom: rem(10px);
        }
        // August 10 start
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($black, 0.3);
            opacity: 0;
            transition: 0.3s;
            z-index: 1;
        }
        .plus-sign{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }
    &-content{
        transition: 0.3s;
        // August 8
        .eyebrow-text {
            color: $gray-500 !important;
        }
    }
    &-card{
        display: block;
        margin-bottom: rem(30px);
        // August 10 start
        @include respond-below(md) {
            margin-bottom: rem(20px);
        }
        // August 10 end
        &:hover{
            .games{
                &-media{
                    box-shadow: rem(10px) rem(10px) 0 $primary-100;
                    transform: translate(rem(-9.54px), rem(-10px));
                    &::before{
                        opacity: 1;
                    }
                    .plus-sign{
                       opacity: 1;
                    }
                }
                &-content{
                    transform: translateX(rem(10px));
                }
            }
        }
        h3, .h3{
            font-size: rem(44px);
            color: $white;
            letter-spacing: -2px;
            @include respond-below(xxxl){
                font-size: rem(34px);
            }
            @include respond-below(xl){
                font-size: rem(32px);
            }
            @include respond-below(md){
                font-size: rem(24px);
            }
            a{
                text-decoration: none;
                &:hover, &:focus{
                    color: $primary-100;
                }
            }
        }
        .store-icons{
            .store-icon{
                color: $white;
                &:hover, &:focus{
                    color: $primary-100;
                }
            }
        }
    }
    &-img, &-video {
        position: relative;
        padding-top: aspect-ratio(16, 9);
        @include imgFit();        
        border: 1px solid $primary-100;
        overflow: hidden;
    }
    &-img {
        @include imgFit();
    }
    &-video {
        video{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }   
    &-slider{
        padding-bottom: rem(88px);
        @include respond-below(lg){
            padding-bottom: rem(50px);
        }
        @include respond-above(md){
            &::after{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 20%;
                height: 100%;
                background-image: linear-gradient(90deg, rgba($black, 0), rgba($secondary-100, 0.99));
                pointer-events: none;
            }
        }
        .slick{
            &-list{
                padding-left: calc((100% - 1710px) / 2);
                padding-right: rem(65px);
                padding-top: rem(11px); 
                @media (max-width: 1900px) {
                    padding-left: rem(60px);
                }
                @include respond-below(md){
                    padding-right: rem(45px);
                    padding-left: rem(20px)  ;                     
                }
            }
            &-arrow{
                top: auto;
                bottom: rem(7px);
                left: 50%;
                transform: translateX(-50%);
                @include respond-below(lg){
                    left: rem(20px);
                    transform: none;
                    bottom: rem(5px);
                }
            }
            &-prev{
                margin-right: rem(77px);
            }
            &-next{
                margin-left: rem(77px);
                @include respond-below(lg){
                    left: auto;
                    right: rem(20px);
                }
            }
            &-dots{
                position: absolute;
                bottom: rem(22px);
                left: 50%;
                transform: translateX(-50%);
                margin: 0;                    
                li{
                    &.slick-active{
                        button{
                            opacity: 1;
                        }
                    }
                }
                button{
                    opacity: 0.3;
                }
            }
        }
    }
}

